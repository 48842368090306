import React, { useEffect, useMemo, useState } from "react";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Menu,
  Chip,
  Button,
  Pagination,
  Dialog
} from "@mui/material";

import { Box, Checkbox, Grid, MenuItem, Typography } from "@mui/material";

import { SlOptionsVertical } from "react-icons/sl";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdNotInterested } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from "@mui/material";
import {
  deleteLeadAction,
  pendingLeadStatusDocsAction,
  findPendingStatusLeadChangeAction,
  leadStatusApprovingChangeAction,
  leadRestartApplicationAction
} from "../../actions/leadActions/leadActions";
import { checkEmployeePrivilegeAction } from "../../actions/employeeManagmentAction";
import { BiSortAlt2 } from "react-icons/bi";

// import "./LeadTable.css";
import usePrivilegeCheck from "../../constants/customHooks/UsePrivilegeCheck";
import AccessDeniedIcon from "../Leads/createLeads/AccessDeniedIcon";
import { Close } from "@mui/icons-material";
import TableNoItemComponent from "../customComponent/TableNoItemComponent";
import PremissionDialog from "../customComponent/premissionDialog";
import Dialogue from "../customComponent/Dialogue";
import { IoEnterOutline } from "react-icons/io5";
import Tag from "../customComponent/Tag";

function LeadsTable({
  showNameColumn,
  showEmailColumn,
  showPrefferedColumn,
  showPhoneColumn,
  showWhatAppNumberColumn,
  showLeadSourceColumn,
  showLeadOwnerColumn,
  showLeadBranchColumn,
  showStatusColumn,
  showPriorityColumn,
  showLeadPlaceColumn,
  showReasonColumn,
  showTelecallerColumn,
  showCounsilorColumn,
  setIdsFunction,
  data,
  columns,
  leadFindLoading,
  selectBoxIdPassHandler,
  component,
  isExistCounsilor,
  pagiantionEntry,
  paginationPageNumber,
  subMenu,
  mainMenu
}) {
  console.log(data, "datadatadatadatadatadata");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    privilege,
    hasPrivilege,
    isApprovePrevilage,
    isCreatePrevilage,
    isDeletePrevilage,
    isUpdatePrevilage,
    isVerifyPrevilage,
    isChangeOwnerPrevilage,
    isChangePriorityPrevilage,
    isChangeStatusPrevilage,
    isAssignCounsilorPrevilage,
    isRestartPrevilage
  } = usePrivilegeCheck();
  const [selectedRows, setSelectedRows] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [reason, setReason] = useState();
  const [rejectReason, setRejectreason] = useState();
  const [isView, setIsView] = useState(false);

  // Alert open state

  const [openAlert, setOpenAlert] = useState(false);
  const [restartAlert, setRestartAlert] = useState(false);
  const [approveAlert, setApproveAlert] = useState(false);
  // pagination

  const [page, setPage] = useState(paginationPageNumber);
  const [rowsPerPage, setRowsPerPage] = useState(pagiantionEntry);

  let { findleadPendingStatusDocsAddSuccess } = useSelector((state) => {
    return state.findPendingStatusLeadChange;
  });

  let { restartApplicationSuccess } = useSelector((state) => {
    return state.leadRestartApplication;
  });

  useEffect(() => {
    setRowsPerPage(pagiantionEntry);
    setPage(paginationPageNumber);
  }, [data, paginationPageNumber, pagiantionEntry]);

  useEffect(() => {
    const maxPage = Math.ceil(data.length / pagiantionEntry);
    if (page > maxPage) {
      setPage(maxPage);
    }
  }, [data, pagiantionEntry, page]);

  useEffect(() => {
    if (component === "pending" && findleadPendingStatusDocsAddSuccess) {
      setPendingData(findleadPendingStatusDocsAddSuccess);
    }
  }, [findleadPendingStatusDocsAddSuccess, component]);

  useEffect(() => {
    dispatch(checkEmployeePrivilegeAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(findPendingStatusLeadChangeAction());
  }, [dispatch, data, restartApplicationSuccess]);

  // pagination

  // Event handler for changing page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Create a function to handle selecting all rows:
  const handleSelectAllRows = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]);
    } else {
      let Ids = sortedData.map((item) => item.id);
      setSelectedRows(Ids);
      selectBoxIdPassHandler(Ids);
    }
  };

  // Create a function to handle selecting individual rows:
  const handleSelectRow = (rowId) => {
    let updatedSelectedRows;
    if (selectedRows.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((id) => id !== rowId);
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      updatedSelectedRows = [...selectedRows, rowId];
      setSelectedRows(updatedSelectedRows);
    }
    setIdsFunction(updatedSelectedRows);
  };

  // table action
  const [leadActionButtonShow, setLeadActionButtonShow] = useState(null);
  const [tableId, setTableId] = useState("");
  const [statusId, setStatusId] = useState("");

  const leadOpenActionButton = Boolean(leadActionButtonShow);

  const handleClickAction = (event, id) => {
    setLeadActionButtonShow(event.currentTarget);
    setTableId(id);
  };

  const handleCloseAction = () => {
    setLeadActionButtonShow(null);
  };

  const CustomSortIcon = ({ direction }) => {
    return direction === "asc" ? (
      <img
        src="/menuIcons/tableUpSort.png"
        className="administratCustomIcon"
        alt="gsl"
        style={{ padding: "15px" }}
      />
    ) : (
      <BiSortAlt2 className="LeadCustomIcon" />
    );
  };

  // sorting table
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const requestSort = (column) => {
    let newSortOrder = "asc";
    if (column === sortBy && sortOrder === "asc") {
      newSortOrder = "desc";
    }

    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  let sortedData = useMemo(() => {
    if (sortBy && data) {
      const sortedItems = [...data].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] < b[sortBy] ? 1 : -1;
        }
      });

      return sortedItems;
    }

    return data;
  }, [data, sortBy, sortOrder]);

  console.log(sortedData, "sortedDatasortedData");

  return (
    <>
      {sortedData?.length > 0 ? (
        <>
          <Typography
            sx={{
              backgroundColor: "#fff",
              padding: "15px",
              paddingRight: "0px",
              borderRadius: "10px 10px 0 0",
              width: "100%",
              fontSize: "16px",
              fontWeight: "400"
            }}
          >
            Showing{" "}
            <Typography
              component={"span"}
              sx={{
                backgroundColor: "#fff",
                padding: "15px",
                paddingLeft: "0px",
                borderRadius: "10px 10px 0 0",
                width: "100%",
                fontSize: "16px",
                // lineHeight: "24px",
                fontWeight: "700"
              }}
            >
              {
                sortedData?.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                ).length
              }{" "}
              Result
            </Typography>
          </Typography>
          <Grid container spacing={2} sx={{ ml: 1 }}>
            <Grid item xs={12} lg={12}>
              {leadFindLoading ? (
                <>
                  {/* <Skeleton /> */}
                  <Typography variant="h1">
                    <Skeleton animation={false} />
                  </Typography>

                  <Typography variant="h3">
                    <Skeleton animation="wave" />
                  </Typography>

                  <Skeleton animation={false} />
                  <Typography variant="h3">
                    <Skeleton animation="wave" />
                  </Typography>

                  <Skeleton animation={false} />
                  <Typography variant="h3">
                    <Skeleton animation="wave" />
                  </Typography>

                  <Skeleton animation={false} />
                  <Typography variant="h3">
                    <Skeleton animation="wave" />
                  </Typography>

                  <Skeleton animation={false} />
                </>
              ) : (
                <div
                  style={{
                    overflowX: "scroll",
                    border: "0px solid #ffff"
                  }}
                  className="scrollBar"
                >
                  <Table className="LeadTable">
                    <TableHead className="LeadTableCell LeadCommon">
                      <TableRow className="LeadTableCell">
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 1,
                            padding: "1px",
                            border: "1px solid #ddd",
                            backgroundColor: "#fafafa"
                          }}
                        >
                          <Checkbox
                            checked={selectedRows.length === sortedData.length}
                            onChange={handleSelectAllRows}
                            style={{ color: "#f81b82" }}
                          />
                        </TableCell>
                        {columns?.map((column) => {
                          if (column.hidden || column.title == null) {
                            return null;
                          }
                          return (
                            <TableCell
                              key={column.field}
                              className="LeadTableCell LeadCommon"
                              style={{
                                padding: "0 16px",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {column.title !== null && (
                                <TableSortLabel
                                  className="LeadHeaderText"
                                  active={column === sortBy}
                                  direction={sortOrder}
                                  onClick={() => requestSort(column)}
                                  IconComponent={
                                    column.field !== "action" && CustomSortIcon
                                  }
                                >
                                  {column.title}
                                </TableSortLabel>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData
                        ?.slice(
                          (page - 1) * rowsPerPage,
                          (page - 1) * rowsPerPage + rowsPerPage
                        )
                        .map((item, indx) => (
                          <TableRow className="LeadTable" key={indx}>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                zIndex: 1, // To ensure it's above other cells
                                backgroundColor: "white", // Adjust as needed
                                padding: "1px",
                                border: "1px solid #ddd"
                              }}
                            >
                              <Checkbox
                                checked={selectedRows.includes(item.id)}
                                onChange={() => handleSelectRow(item.id)}
                                style={{ color: "#f81b82" }}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                padding: "0 16px",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {indx + 1}
                            </TableCell>

                            {!showNameColumn && (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  // marginTop: "10px",
                                  whiteSpace: "nowrap"
                                  // display: "flex",
                                  // alignItems: "center",
                                  // borderBottom: "none"
                                }}
                              >
                                {component === "leads" ||
                                component === "deals" ? (
                                  <span
                                    style={{
                                      // color: "blue",
                                      fontSize: "14px",
                                      textDecoration: "underline",
                                      cursor: "pointer"
                                    }}
                                    onClick={() =>
                                      navigate(
                                        `/Leads/details/${item.id}/${subMenu}/${mainMenu}`
                                      )
                                    }
                                  >
                                    {item.name}
                                  </span>
                                ) : (
                                  item.name
                                  // <Box sx={{ ml: 1, mt: 0.5 }}>
                                  //   <IoEnterOutline
                                  //     style={{
                                  //       fontSize: "lg",
                                  //       width: "20px",
                                  //       height: "19px",
                                  //       textDecoration: "none",
                                  //       cursor: "pointer"
                                  //     }}
                                  //     onClick={() =>
                                  //       navigate(
                                  //         `/Leads/details/${item.id}/${subMenu}/${mainMenu}`
                                  //       )
                                  //     }
                                  //     className="icons"
                                  //   />
                                  // </Box>
                                )}
                              </TableCell>
                            )}

                            {!showEmailColumn && (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.email}
                              </TableCell>
                            )}

                            {!showPhoneColumn && (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.countryId
                                  ? item.countryId + item.phone
                                  : item.phone}
                              </TableCell>
                            )}

                            {!showWhatAppNumberColumn && (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.whatsappNumber}
                              </TableCell>
                            )}

                            {component == "leads" || component == "deals" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.passportNumber
                                  ? item.passportNumber
                                  : "-"}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {component == "leads" || component == "deals" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap",
                                  // display: "flex",
                                  flexWrap: "wrap",
                                  gap: "8px",
                                  textDecoration: "none"
                                }}
                              >
                                {item.tags ? (
                                  <Tag
                                    text={"Passport Duplicate"}
                                    color={"rgba(255, 45, 46, 1)"}
                                  />
                                ) : null}

                                {item.registrationFeeTag ? (
                                  <Tag
                                    text={"Reg fee approval waiting"}
                                    color={"rgba(0, 0, 255)"}
                                  />
                                ) : null}

                                {item.tutionFeeTag ? (
                                  <Tag
                                    text={"Tuition fee approval waiting"}
                                    color={"rgba(127, 0, 255)"}
                                  />
                                ) : null}

                                {item.deferralTag ? (
                                  <Tag
                                    text={"status approval waiting"}
                                    color={"rgba(255, 165, 0)"}
                                  />
                                ) : null}

                                {item.dropTag ? (
                                  <Tag
                                    text={"status approval waiting"}
                                    color={"rgba(255, 165, 0)"}
                                  />
                                ) : null}

                                {item.ownerChangeTag ? (
                                  <Tag
                                    text={"owner change approval waiting"}
                                    color={"rgba(255, 29, 141)"}
                                  />
                                ) : null}

                                {item.leadEmailEditTag ? (
                                  <Tag
                                    text={"email change approval waiting"}
                                    color={"rgba(205, 127, 50)"}
                                  />
                                ) : null}

                                {item.leadSourceEditTag ? (
                                  <Tag
                                    text={"source approval waiting"}
                                    color={"rgba(205, 127, 50)"}
                                  />
                                ) : null}

                                {item.leadPhoneNumberEditTag ? (
                                  <Tag
                                    text={"mobile change approval waiting"}
                                    color={"rgba(205, 127, 50)"}
                                  />
                                ) : null}

                                {item.leadDocumentEditTag ? (
                                  <Tag
                                    text={"Doc approval waiting"}
                                    color={"rgba(0, 128, 0)"}
                                  />
                                ) : null}

                                {item.leadPassportVerifyTag ? (
                                  <Tag
                                    text={"Passport verification"}
                                    color={"rgba(135, 206, 235)"}
                                  />
                                ) : null}

                                {item.leadPassportEditTag ? (
                                  <Tag
                                    text={"Passport Edit"}
                                    color={"rgba(144, 238, 144)"}
                                  />
                                ) : null}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {!showPrefferedColumn &&
                              (component !== "approvingStatus" &&
                              component !== "leadDeferral" &&
                              component !== "pending" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.preferredCountry}
                                </TableCell>
                              ) : component === "approvingStatus" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.status}&nbsp;&nbsp;&nbsp;&nbsp;
                                </TableCell>
                              ) : component === "leadDeferral" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.leadSource}&nbsp;&nbsp;&nbsp;&nbsp;
                                </TableCell>
                              ) : null)}

                            {!showLeadSourceColumn &&
                              (component !== "approvingStatus" &&
                              component !== "leadDeferral" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.leadSource ? item.leadSource : "-"}
                                </TableCell>
                              ) : component === "approvingStatus" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.currentStatus}
                                </TableCell>
                              ) : (
                                component === "leadDeferral" && (
                                  <TableCell
                                    style={{
                                      padding: "0 16px",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {item.leadCity}
                                  </TableCell>
                                )
                              ))}

                            {component === "approvingStatus" && (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.rejectReason ? item.rejectReason : "-"}
                              </TableCell>
                            )}

                            {!showLeadOwnerColumn &&
                              (component !== "approvingStatus" &&
                              component !== "leadDeferral" &&
                              item.leadOwner !== null ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.leadOwner ? item.leadOwner : "-"}
                                </TableCell>
                              ) : component === "approvingStatus" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.leadSource}
                                </TableCell>
                              ) : component === "leadDeferral" &&
                                !showPrefferedColumn ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.prefferedCountry}
                                </TableCell>
                              ) : (
                                ""
                              ))}

                            {(!showLeadBranchColumn &&
                              component == "leadDeferral") ||
                            component == "DeadLeads" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.leadBranch ? item.leadBranch : "-"}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {!showReasonColumn &&
                            component == "leadDeferral" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.intakeMonth}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {!showReasonColumn &&
                            component == "leadDeferral" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.intakeYear}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {component !== "approvingStatus" ? (
                              component !== "leadDeferral" ? (
                                component !== "DeadLeads" ? (
                                  <TableCell
                                    style={{
                                      padding: "0 16px",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {item.leadBranch ? item.leadBranch : "-"}
                                  </TableCell>
                                ) : null
                              ) : null
                            ) : component === "approvingStatus" &&
                              component !== "leadDeferral" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.leadOwner}
                              </TableCell>
                            ) : null}

                            {!showStatusColumn &&
                              (component !== "approvingStatus" &&
                              component !== "leadDeferral" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  <Chip
                                    sx={{
                                      height: "auto",
                                      borderRadius: "3px",
                                      backgroundColor:
                                        item.status === "new"
                                          ? "rgba(255, 122, 0, 0.1)"
                                          : item.status === "warm"
                                          ? "rgb(220, 250, 195)"
                                          : item.status === "cold"
                                          ? "rgb(225, 247, 252)"
                                          : item.status === "deferral"
                                          ? "rgb(255,194,194)"
                                          : item.status ===
                                            "Application Started"
                                          ? "rgba(255, 122, 0, 0.1)"
                                          : item.status ===
                                            "Documents is waiting"
                                          ? "rgb(220, 250, 195)"
                                          : item.status === "SOP in Progress"
                                          ? "rgb(225, 247, 252)"
                                          : item.status ===
                                            "Application in progress"
                                          ? "rgb(255,194,194)"
                                          : item.status ===
                                            "Application Completed"
                                          ? "rgb(255,194,194)"
                                          : "",
                                      color:
                                        item.status === "new"
                                          ? "rgba(255, 122, 0, 1)"
                                          : item.status === "warm"
                                          ? "rgb(105,162,66)"
                                          : item.status === "cold"
                                          ? "rgb(44, 202, 242)"
                                          : item.status === "deferral"
                                          ? "rgba(255,69,69)"
                                          : item.status ===
                                            "Application Started"
                                          ? "rgba(255, 122, 0, 1)"
                                          : item.status ===
                                            "Documents is waiting"
                                          ? "rgb(105,162,66)"
                                          : item.status === "SOP in Progress"
                                          ? "rgb(44, 202, 242)"
                                          : item.status ===
                                            "Application in progress"
                                          ? "rgba(255,69,69)"
                                          : item.status ===
                                            "Application Completed"
                                          ? "rgba(255, 122, 0, 1)"
                                          : "",
                                      "& .MuiChip-label": {
                                        display: "block",
                                        whiteSpace: "normal",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        width: "max-content"
                                      }
                                    }}
                                    label={
                                      item.status?.[0]?.toUpperCase() +
                                      item.status?.slice(1)
                                    }
                                  />
                                  &nbsp;
                                </TableCell>
                              ) : component === "approvingStatus" &&
                                component !== "leadDeferral" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.leadCity}
                                </TableCell>
                              ) : null)}
                            {!showPriorityColumn &&
                              component !== "approvingStatus" &&
                              component !== "leadDeferral" && (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  <Chip
                                    sx={{
                                      height: "auto",
                                      borderRadius: "3px",
                                      backgroundColor:
                                        item.priority === "High"
                                          ? "rgba(255, 26, 12, 0.1)"
                                          : item.priority === "Low"
                                          ? "rgba(12, 197, 255, 0.1)"
                                          : item.priority === "Normal"
                                          ? "rgba(53, 20, 255, 0.1)"
                                          : null,
                                      color:
                                        item.priority === "High"
                                          ? "rgba(255, 15, 0, 1)"
                                          : item.priority === "Low"
                                          ? "rgba(12, 197, 255, 1)"
                                          : item.priority === "Normal"
                                          ? "rgba(92, 66, 255, 1)"
                                          : null,
                                      "& .MuiChip-label": {
                                        display: "block",
                                        whiteSpace: "normal",
                                        fontSize: "14px",
                                        fontWeight: 400
                                      }
                                    }}
                                    label={item.priority ? item.priority : "-"}
                                  />
                                </TableCell>
                              )}
                            {!showLeadPlaceColumn &&
                              component !== "approvingStatus" &&
                              component !== "leadDeferral" && (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {item.leadCity}
                                </TableCell>
                              )}

                            {component == "leadDrop" && (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.Reason}
                              </TableCell>
                            )}

                            {/* {component === "pending" && (
                              <>
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  {pendingData?.some(
                                    (data) => data.leadId == item.id
                                  ) ? (
                                    pendingData.map((data) => {
                                      console.log(
                                        item,
                                        "datad56566654654atadatadatadatadatadatadatadatadatadatadatadatadatadatadatadatadata"
                                      );
                                      if (data.leadId == item.id) {
                                        // Display status from pendingData
                                        return (
                                          <Chip
                                            key={data.id}
                                            sx={{
                                              height: "auto",
                                              borderRadius: "3px",
                                              backgroundColor:
                                                data.status === "approved"
                                                  ? "rgba(34, 130, 1, 0.2)"
                                                  : "rgba(229, 21, 30, 0.2)",
                                              color:
                                                data.status === "approved"
                                                  ? "rgba(34, 130, 0, 1)"
                                                  : "rgba(229, 41, 0, 1)",
                                              "& .MuiChip-label": {
                                                display: "block",
                                                whiteSpace: "normal",
                                                fontSize: "14px",
                                                fontWeight: 400
                                              }
                                            }}
                                            label={
                                              data.status === "approved"
                                                ? "Approved"
                                                : "Rejected"
                                            }
                                          />
                                        );
                                      }
                                      return null; // Skip rendering for other elements in pendingData
                                    })
                                  ) : (
                                    <>
                                      <Button
                                        sx={{
                                          backgroundColor:
                                            "rgba(34, 130, 0, 1)",
                                          color: "white",
                                          width: "76px",
                                          height: "25px",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          mr: 2,
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(34, 110, 0, 0.5)"
                                          }
                                        }}
                                        onClick={() => {
                                          if (isApprovePrevilage(subMenu)) {
                                            dispatch(
                                              pendingLeadStatusDocsAction(
                                                item.id,
                                                "approved"
                                              )
                                            );
                                          } else {
                                            setApproveAlert(true);
                                          }
                                        }}
                                      >
                                        Approved
                                      </Button>
                                      <Button
                                        sx={{
                                          backgroundColor:
                                            "rgba(229, 41, 0, 1)",
                                          color: "white",
                                          width: "76px",
                                          height: "25px",
                                          fontWeight: 400,
                                          fontSize: "12px",
                                          ml: 1,
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(229, 21, 30, 0.5)"
                                          }
                                        }}
                                        onClick={() => {
                                          if (isApprovePrevilage(subMenu)) {
                                            setReason(true);
                                            setTableId(item.id);
                                          } else {
                                            setApproveAlert(true);
                                          }
                                        }}
                                      >
                                        Reject
                                      </Button>
                                    </>
                                  )}
                                </TableCell>
                              </>
                            )} */}

                            {component === "pending" && (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                <Box
                                  onClick={() => {
                                    setIsView(true);
                                    setTableId(item.id);
                                  }}
                                  // href={item?.receipt}
                                  // style={{ paddingLeft: "30px" }}
                                  // target="blank"
                                >
                                  <Button
                                    sx={{
                                      backgroundColor: "#141E3C",
                                      color: "white",
                                      width: "76px",
                                      height: "25px",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      mr: 2,
                                      "&:hover": {
                                        backgroundColor: "rgba(34, 110, 0, 0.5)"
                                      }
                                    }}
                                  >
                                    view
                                  </Button>
                                </Box>
                              </TableCell>
                            )}

                            {/* {component === "pending" && (
                              <>
                                <>
                                  <TableCell
                                    style={{
                                      padding: "0 16px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item?.reason ? item?.reason : "-"}
                                  </TableCell>
                                </>
                              </>
                            )} */}

                            {!showTelecallerColumn &&
                            item.leadTeleCaller !== null
                              ? component !== "approvingStatus" && (
                                  <TableCell
                                    style={{
                                      padding: "0 16px",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {item.leadTeleCaller === "-" ? (
                                      <Chip
                                        sx={{
                                          height: "auto",
                                          borderRadius: "3px",
                                          backgroundColor:
                                            item.leadTeleCaller === "-"
                                              ? null
                                              : null,
                                          color:
                                            item.leadTeleCaller === "-"
                                              ? null
                                              : null,

                                          "& .MuiChip-label": {
                                            display: "block",
                                            whiteSpace: "normal",
                                            fontSize: "14px",
                                            fontWeight: 400
                                          }
                                        }}
                                        label={item.leadTeleCaller}
                                      />
                                    ) : (
                                      <>{item.leadTeleCaller}</>
                                    )}
                                  </TableCell>
                                )
                              : null}

                            {component == "leads" || component == "deals" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.TeleCallerNote
                                  ? item.TeleCallerNote
                                  : "No telecaller note"}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {!showCounsilorColumn && item.leadCounsilor !== null
                              ? component !== "approvingStatus" && (
                                  <TableCell
                                    style={{
                                      padding: "0 16px",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {item.leadCounsilor === "-" ? (
                                      <Chip
                                        sx={{
                                          height: "auto",
                                          borderRadius: "3px",
                                          backgroundColor:
                                            item.leadCounsilor === "-"
                                              ? null
                                              : null,
                                          color:
                                            item.leadCounsilor === "-"
                                              ? null
                                              : null,

                                          "& .MuiChip-label": {
                                            display: "block",
                                            whiteSpace: "normal",
                                            fontSize: "14px",
                                            fontWeight: 400
                                          }
                                        }}
                                        label={item.leadCounsilor}
                                      />
                                    ) : (
                                      <>{item.leadCounsilor}</>
                                    )}
                                  </TableCell>
                                )
                              : null}

                            {component == "leads" || component == "deals" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.CounsilorNote
                                  ? item.CounsilorNote
                                  : "No counsilor note"}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {component == "leads" || component == "deals" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.marketingNote
                                  ? item.marketingNote
                                  : "No marketing note"}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {component == "DeadLeads" ? (
                              <TableCell
                                style={{
                                  padding: "0 16px",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {item.reason ? item.reason : "Reason not given"}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {component !== "pending" ? (
                              component !== "approvingStatus" &&
                              component !== "leadDeferral" ? (
                                <TableCell
                                  style={{
                                    padding: "0 16px",
                                    whiteSpace: "nowrap"
                                  }}
                                >
                                  <SlOptionsVertical
                                    id="demo-positioned-button"
                                    aria-controls={
                                      leadOpenActionButton
                                        ? "demo-positioned-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      leadOpenActionButton ? "true" : undefined
                                    }
                                    onClick={(e) => {
                                      handleClickAction(e, item.id);
                                    }}
                                    variant="outlined"
                                    style={{
                                      marginLeft: "10px",
                                      color: "#F80B7A"
                                    }}
                                    sx={{
                                      backgroundColor: "#141E3C",
                                      size: "10px",
                                      padding: 1,
                                      margin: 3,
                                      color: "#fff",
                                      width: "75px",
                                      height: "40px"
                                    }}
                                  />
                                  {/* TABLE ACTION MENU START*/}
                                  <Menu
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={leadActionButtonShow}
                                    open={leadActionButtonShow}
                                    onClose={handleCloseAction}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button"
                                    }}
                                    anchorOrigin={{
                                      vertical: "bottom", // Change from "top" to "bottom"
                                      horizontal: "left"
                                    }}
                                    transformOrigin={{
                                      vertical: "top", // Change from "top" to "bottom"
                                      horizontal: "left"
                                    }}
                                    sx={{
                                      borderColor: "black",
                                      borderRadius: "24px",
                                      mt: 1.5,
                                      width: "auto",
                                      height: "auto",
                                      boxShadow: "none"
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end"
                                      }}
                                    >
                                      <MenuItem>
                                        <AiOutlineEye
                                          style={{
                                            fontSize: "lg",
                                            width: "20px",
                                            height: "19px"
                                          }}
                                          onClick={() =>
                                            navigate(
                                              `/Leads/details/${tableId}/${subMenu}/${mainMenu}`
                                            )
                                          }
                                        />
                                      </MenuItem>

                                      <MenuItem>
                                        {isDeletePrevilage(subMenu) ? (
                                          <RiDeleteBin6Line
                                            style={{
                                              fontSize: "lg",
                                              width: "20px",
                                              height: "19px"
                                            }}
                                            onClick={() => {
                                              Swal.fire({
                                                title: "Are you sure?",
                                                text: "You won't be able to revert this!",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText:
                                                  "Yes, delete it!"
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  dispatch(
                                                    deleteLeadAction(tableId)
                                                  );
                                                }
                                              });
                                            }}
                                          />
                                        ) : (
                                          <>
                                            <MdNotInterested
                                              style={{
                                                fontSize: "lg",
                                                width: "20px",
                                                height: "19px"
                                              }}
                                              onClick={() => setOpenAlert(true)}
                                            />
                                            <Dialog
                                              open={openAlert}
                                              onClose={() =>
                                                setOpenAlert(false)
                                              }
                                              aria-labelledby="alert-dialog-title"
                                              aria-describedby="alert-dialog-description"
                                              sx={{
                                                zIndex: "9999",
                                                // background:"red",
                                                "& .MuiDialog-paper": {
                                                  width: "100%",
                                                  borderRadius: "5px",
                                                  p: "15px",
                                                  textAlign: "center"
                                                }
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent: "end"
                                                }}
                                              >
                                                <Close
                                                  sx={{
                                                    cursor: "pointer"
                                                  }}
                                                  onClick={() =>
                                                    setOpenAlert(false)
                                                  }
                                                />
                                              </Box>
                                              <Typography
                                                sx={{
                                                  color: "#05050F",
                                                  fontSize: "26px",
                                                  fontWeight: "700"
                                                }}
                                              >
                                                Access Denied
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  mt: "-10px"
                                                }}
                                              >
                                                <AccessDeniedIcon />
                                              </Box>
                                              <Typography
                                                sx={{
                                                  color: "#717171",
                                                  fontSize: "16px",
                                                  mb: "10px"
                                                }}
                                              >
                                                Sorry, you don’t have permission
                                                to delete
                                              </Typography>
                                            </Dialog>
                                          </>
                                        )}
                                      </MenuItem>
                                    </Box>
                                  </Menu>
                                </TableCell>
                              ) : component === "approvingStatus" &&
                                component !== "leadDeferral" ? (
                                isApprovePrevilage(subMenu) && (
                                  <TableCell
                                    style={{
                                      padding: "0 16px",
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {item?.approvalStatus == "approved" ||
                                    item?.approvalStatus == "rejected" ? (
                                      <Chip
                                        key={item.id}
                                        sx={{
                                          height: "auto",
                                          borderRadius: "3px",
                                          backgroundColor:
                                            item?.approvalStatus === "approved"
                                              ? "rgba(34, 130, 1, 0.2)"
                                              : item?.approvalStatus ==
                                                "rejected"
                                              ? "rgba(229, 21, 30, 0.2)"
                                              : "rgba(229, 21, 30, 0.2)",
                                          color:
                                            item?.approvalStatus === "approved"
                                              ? "rgba(34, 130, 0, 1)"
                                              : item?.approvalStatus ==
                                                "rejected"
                                              ? "rgba(34, 130, 0, 1)"
                                              : "rgba(229, 21, 30, 0.2)",
                                          "& .MuiChip-label": {
                                            display: "block",
                                            whiteSpace: "normal",
                                            fontSize: "14px",
                                            fontWeight: 400
                                          }
                                        }}
                                        label={
                                          item?.approvalStatus === "approved"
                                            ? "Approved"
                                            : "Rejected"
                                        }
                                      />
                                    ) : (
                                      <>
                                        <Button
                                          sx={{
                                            backgroundColor:
                                              "rgba(34, 130, 0, 1)",
                                            color: "white",
                                            width: "76px",
                                            height: "25px",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            mr: 2,
                                            "&:hover": {
                                              backgroundColor:
                                                "rgba(34, 110, 0, 0.5)"
                                            }
                                          }}
                                          onClick={() => {
                                            dispatch(
                                              leadStatusApprovingChangeAction({
                                                status: "approved",
                                                leadId: item.id,
                                                statusId: item.statusId,
                                                requestStatus: item.status
                                              })
                                            );

                                            Swal.fire(
                                              "approved!",
                                              "request has been approved.",
                                              "success"
                                            );
                                          }}
                                        >
                                          Approve
                                        </Button>
                                        <Button
                                          sx={{
                                            backgroundColor:
                                              "rgba(229, 41, 0, 1)",
                                            color: "white",
                                            width: "76px",
                                            height: "25px",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            ml: 1,
                                            "&:hover": {
                                              backgroundColor:
                                                "rgba(229, 21, 30, 0.5)"
                                            }
                                          }}
                                          onClick={() => {
                                            setRejectreason(true);
                                            setTableId(item.id);
                                            setStatusId(item.statusId);

                                            // dispatch(
                                            //   leadStatusApprovingChangeAction({
                                            //     status: "rejected",
                                            //     leadId: item.id,
                                            //     statusId: item.statusId,
                                            //     requestStatus: item.status
                                            //   })
                                            // );
                                            // Swal.fire(
                                            //   "Rejected!",
                                            //   "request has been rejected.",
                                            //   "success"
                                            // );
                                          }}
                                        >
                                          Reject
                                        </Button>
                                      </>
                                    )}
                                  </TableCell>
                                )
                              ) : component === "leadDeferral" &&
                                isRestartPrevilage(subMenu) ? (
                                <TableCell>
                                  <Button
                                    sx={{
                                      backgroundColor: "rgba(34, 130, 0, 1)",
                                      color: "white",
                                      width: "76px",
                                      height: "25px",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      mr: 2,
                                      "&:hover": {
                                        backgroundColor: "rgba(34, 110, 0, 0.5)"
                                      }
                                    }}
                                    onClick={() => {
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "do You want be able to continues this lead application ?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!"
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          dispatch(
                                            leadRestartApplicationAction(
                                              item.id,
                                              "restarted"
                                            )
                                          );
                                          Swal.fire(
                                            "continue!",
                                            "user can continue their application.",
                                            "success"
                                          );
                                        }
                                      });
                                    }}
                                  >
                                    Restart
                                  </Button>{" "}
                                </TableCell>
                              ) : (
                                <TableCell>
                                  <Button
                                    sx={{
                                      backgroundColor: "rgba(34, 130, 0, 1)",
                                      color: "white",
                                      width: "76px",
                                      height: "25px",
                                      fontWeight: 400,
                                      fontSize: "12px",
                                      mr: 2,
                                      "&:hover": {
                                        backgroundColor: "rgba(34, 110, 0, 0.5)"
                                      }
                                    }}
                                    onClick={() => {
                                      setRestartAlert(true);
                                    }}
                                  >
                                    Restart
                                  </Button>{" "}
                                </TableCell>
                              )
                            ) : (
                              ""
                            )}

                            {/* {/*TABLE ACTION MENU END */}
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter />
                  </Table>
                </div>
              )}
            </Grid>
          </Grid>
          <Pagination
            sx={{
              mt: "28px",
              "& .Mui-selected": {
                backgroundColor: "#f81b82 !important",
                color: "#fff"
              },
              "& .MuiPagination-ul": {
                justifyContent: "end"
              }
            }}
            count={Math.ceil(data.length / rowsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </>
      ) : (
        <>
          <TableNoItemComponent />
        </>
      )}
      <Dialog
        open={restartAlert}
        onClose={() => setRestartAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "9999",
          // background:"red",
          "& .MuiDialog-paper": {
            width: "100%",
            borderRadius: "5px",
            p: "15px",
            textAlign: "center"
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end"
          }}
        >
          <Close
            sx={{
              cursor: "pointer"
            }}
            onClick={() => setRestartAlert(false)}
          />
        </Box>
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "26px",
            fontWeight: "700"
          }}
        >
          Access Denied
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: "-10px"
          }}
        >
          <AccessDeniedIcon />
        </Box>
        <Typography
          sx={{
            color: "#717171",
            fontSize: "16px",
            mb: "10px"
          }}
        >
          Sorry, you don’t have permission to restart
        </Typography>
      </Dialog>

      {approveAlert && (
        <PremissionDialog
          openAlert={approveAlert}
          handleClose={() => setApproveAlert(false)}
        />
      )}

      {reason && (
        <Dialogue
          openTrue={reason}
          handleClose={() => setReason(false)}
          headerName="Reason"
          Dailogcomponent="RejectReason"
          appliedApplicationId={tableId}
          modalData={"rejected"}
        />
      )}

      {rejectReason && (
        <Dialogue
          openTrue={rejectReason}
          handleClose={() => setRejectreason(false)}
          headerName="Reason"
          Dailogcomponent="statusApprovalReject"
          appliedApplicationId={tableId}
          modalData={statusId}
        />
      )}

      {isView && (
        <Dialogue
          openTrue={isView}
          handleClose={() => setIsView(false)}
          headerName="Uploaded Documents"
          Dailogcomponent="uploadedDocuments"
          appliedApplicationId={tableId}
          modalData={statusId}
          width="871px"
        />
      )}
    </>
  );
}

export default LeadsTable;
