import React, { useEffect, useState } from "react";

import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { findcountryMasterAddAction } from "../../../actions/countryMasterAction";
import {
  changeSopApplicationStatusAction,
  leadDocumentDeleteAction,
  leadSopFindingAction,
  leadSopUploadingAction
} from "../../../actions/leadActions/leadDocsActions";
import { useParams } from "react-router-dom";

import "./sopDetails.css";
import { Chip } from "@material-ui/core";
import CountryFlag from "../../customComponent/CountryFlag";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { IoEyeOutline } from "react-icons/io5";
import { CiRepeat } from "react-icons/ci";
import { AiTwotoneDelete } from "react-icons/ai";
import { GoDownload } from "react-icons/go";
import { LEAD_DOCUMENT_DELETE_SUCCESS } from "../../../constants/leadConstant";

function SopDetails({ handleComplete, handleCompleteTick }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [sopFile, setSopFile] = useState(null);
  const [statusVerify, setStatusVerify] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const handleMouseEnter = (icon) => setHoveredIcon(icon);
  const handleMouseLeave = () => setHoveredIcon(null);

  const iconStyle = {
    width: "22px",
    height: "22px",
    marginRight: "10px",
    transition: "color 0.3s ease" // Smooth color transition
  };

  const iconHoverStyle = {
    color: "red" // Color on hover
  };

  let { countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  let { leadSopUploadingSuccess } = useSelector((state) => {
    return state.leadSopUploading;
  });

  let { leadSopFindingSuccess } = useSelector((state) => {
    return state.leadSopFinding;
  });

  let { leadSopStatusChangeSuccess } = useSelector((state) => {
    return state.changeSopApplicationStatus;
  });

  let { leadDocumentDeleteSuccess } = useSelector((state) => {
    return state.leadDocumentDelete;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (countryFindSuccess && leadSopFindingSuccess) {
      const notEqualCountries = countryFindSuccess.filter((country) => {
        return !leadSopFindingSuccess.some(
          (sop) => sop.countryName === country.countryName
        );
      });

      setCountryList(notEqualCountries);
    }
  }, [countryFindSuccess, leadSopFindingSuccess]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction(id,"sop"));
  }, [dispatch, leadSopUploadingSuccess, leadSopStatusChangeSuccess]);

  useEffect(() => {
    dispatch(leadSopFindingAction(id));
  }, [
    dispatch,
    leadSopUploadingSuccess,
    id,
    leadSopStatusChangeSuccess,
    leadDocumentDeleteSuccess
  ]);

  // useEffect(() => {
  //   if (leadSopFindingSuccess) {
  //     if (leadSopFindingSuccess?.length !== 0) {
  //       handleCompleteTick();
  //     }
  //   }
  // }, [leadSopFindingSuccess, handleCompleteTick]);

  // success alert for file upload

  useEffect(() => {
    if (leadSopUploadingSuccess && successAlertMsgSuccess) {
      Swal.fire({
        icon: "success",
        title: "Good job \u{1F44D} ",
        text: leadSopUploadingSuccess
      });
      dispatch(successTrueMsgAction(false));
    }
  }, [leadSopUploadingSuccess, successAlertMsgSuccess]);

  // success alert for file upload

  // useEffect(() => {
  //   if (
  //     leadDocumentDeleteSuccess &&
  //     leadDocumentDeleteSuccess?.doctype == "sop"
  //   ) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Good job \u{1F44D} ",
  //       text: "Deleted successfully"
  //     });
  //     dispatch({ type: LEAD_DOCUMENT_DELETE_SUCCESS, payload: false });
  //   }
  // }, [leadDocumentDeleteSuccess]);

  const handleSopDocChange = (event, countryId, countryName) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      // Read the image file as a data URL
      reader.readAsDataURL(file);

      // Set the previewImage state when the reader has finished loading the image
      reader.onloadend = () => {
        dispatch(
          leadSopUploadingAction(reader.result, countryId, id, countryName)
        );
        dispatch(successTrueMsgAction(true));
      };
    }
  };

  console.log(
    countryFindSuccess,
    "countryFindSuccesscountryFindSuccesscountryFindSuccess"
  );
  return (
    <>
      <Grid container spacing={2}>
        {countryList?.length == 0 && leadSopFindingSuccess?.length == 0 ? (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "24px",
              fontFamily: "Montserrat,sans-serif",
              textAlign: "center",
              mt: 10,
              ml: 5,
              mt: 5
            }}
          >
            No documents to show.
          </Typography>
        ) : (
          ""
        )}
        {countryList?.map((data) => (
          <Grid item xs={6} md={4} lg={3} key={data.id}>
            <Box mt={1} mb={5}>
              <Box
                sx={{
                  width: "auto",
                  height: "82px",
                  backgroundColor: "white",
                  border: "1.5px dashed rgba(25, 118, 210, 0.5)",
                  borderRadius: "5px"
                }}
              >
                {!sopFile && (
                  <label
                  htmlFor={`avatar-file-${data.id}`}
                    style={{
                      margin: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="rounded-flag">
                        <CountryFlag
                          countryCode={data.countryCode}
                          component="stepper"
                          width="39px"
                          heigth="39px"
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "10px"
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "24px",
                            fontFamily: "Montserrat,sans-serif"
                          }}
                        >
                          {data?.countryName.split("(")[0]}
                        </Typography>
                        {/* <Typography
                          sx={{
                            color: "#ACB1C6",
                            fontWeight: 300,
                            fontSize: "15px",
                            lineHeight: "20px",
                          }}
                        >
                          SOP Documents
                        </Typography> */}
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="/menuIcons/uploadBlue.png"
                        alt="gsl_download"
                        style={{ cursor: "pointer", color: "#1976D2" }}
                      />
                      <div
                        style={{
                          marginLeft: "8px",
                          color: "#1976D2",
                          fontWeight: 400,
                          fontFamily: "Montserrat,sans-serif",
                          fontSize: "20px"
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  </label>
                )}
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.xlsx"
                  id={`avatar-file-${data.id}`}
                  style={{ display: "none" }}
                  onChange={(e) =>
                    handleSopDocChange(e, data.id, data.countryName)
                  }
                />
              </Box>
            </Box>
          </Grid>
        ))}

        {leadSopFindingSuccess?.map((sopData, index) => (
          <Grid item xs={6} md={4} lg={3}>
            <Box mt={1} mb={5} position="relative">
              <Box
                // id="container"
                sx={{
                  width: "auto",
                  height: "82px",
                  backgroundColor: "white",
                  border: "1.5px dashed #ECECEC",
                  borderRadius: "15px"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    margin: "15px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="rounded-flag">
                      <CountryFlag
                        countryCode={sopData.countryCode.countryCode}
                        component="stepper"
                        width="39px"
                        heigth="39px"
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "10px"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          lineHeight: "24px",
                          fontFamily: "Montserrat,sans-serif"
                        }}
                      >
                        {sopData?.countryName.split("(")[0]}
                      </Typography>

                      <Box sx={{ display: "flex" }}>
                        {statusVerify[index] !== true && (
                          <>
                            <a
                              href={sopData.pdfUrl}
                              download={sopData.pdfUrl}
                              target="blank"
                              style={{
                                textDecoration: "none",
                                color: "black"
                              }}
                            >
                              <Tooltip title="view">
                                <IconButton
                                  sx={{ padding: 0, marginRight: "10px" }}
                                >
                                  <IoEyeOutline
                                    style={{
                                      ...iconStyle,
                                      color:
                                        hoveredIcon === "eye"
                                          ? iconHoverStyle.color
                                          : "black",
                                      cursor: "pointer"
                                    }}
                                    onMouseEnter={() => handleMouseEnter("eye")}
                                    onMouseLeave={handleMouseLeave}
                                  />
                                </IconButton>
                              </Tooltip>
                            </a>

                            {sopData.status !== "approved" && (
                              <Tooltip title="Change status">
                                <IconButton
                                  sx={{ padding: 0, marginRight: "10px" }}
                                >
                                  <CiRepeat
                                    style={{
                                      ...iconStyle,
                                      color:
                                        hoveredIcon === "repeat"
                                          ? iconHoverStyle.color
                                          : "black",
                                      cursor: "pointer"
                                    }}
                                    onMouseEnter={() =>
                                      handleMouseEnter("repeat")
                                    }
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() =>
                                      setStatusVerify((prevStatus) => ({
                                        ...prevStatus,
                                        [index]: true
                                      }))
                                    }
                                  />
                                </IconButton>
                              </Tooltip>
                            )}

                            <a
                              href={sopData.pdfUrl}
                              download={sopData.pdfUrl}
                              target="blank"
                              style={{
                                textDecoration: "none",
                                color: "black"
                              }}
                            >
                              <Tooltip title="Download">
                                <IconButton
                                  sx={{ padding: 0, marginRight: "10px" }}
                                >
                                  <GoDownload
                                    style={{
                                      ...iconStyle,
                                      color:
                                        hoveredIcon === "download"
                                          ? iconHoverStyle.color
                                          : "black",
                                      cursor: "pointer"
                                    }}
                                    onMouseEnter={() =>
                                      handleMouseEnter("download")
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  />
                                </IconButton>
                              </Tooltip>
                            </a>

                            {sopData.status !== "verified" &&
                            sopData.status !== "approved" ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  sx={{ padding: 0, marginRight: "10px" }}
                                >
                                  <AiTwotoneDelete
                                    style={{
                                      ...iconStyle,
                                      color:
                                        hoveredIcon === "delete"
                                          ? iconHoverStyle.color
                                          : "black",
                                      cursor: "pointer"
                                    }}
                                    onMouseEnter={() =>
                                      handleMouseEnter("delete")
                                    }
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => {
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!"
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          dispatch(
                                            leadDocumentDeleteAction(
                                              sopData.id,
                                              id,
                                              "sop"
                                            )
                                          );
                                          Swal.fire(
                                            "Deleted!",
                                            "Your file has been deleted.\u{1F44D}",
                                            "success"
                                          );
                                        }
                                      });
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        {statusVerify[index] === true && (
                          <Box
                            id="icon"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backdropFilter: "blur(8px)",
                              opacity: 0,
                              transition: "opacity 0.3s",
                              zIndex: 1,
                              " &:hover ": {
                                opacity: 1
                              }
                            }}
                          >
                            <>
                              <Chip
                                label="Reupload"
                                color="warning"
                                variant="outlined"
                                style={{
                                  marginLeft: "-10px",
                                  fontSize: "1em",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  color: "rgba(255, 153, 0, 1)",
                                  borderColor: "rgba(255, 153, 0, 1)",
                                  position: "relative",
                                  zIndex: 2
                                }}
                                onClick={() => {
                                  // alert("sdfsdfjklk");
                                  dispatch(
                                    changeSopApplicationStatusAction(
                                      sopData.leadId,
                                      sopData.countryId,
                                      "reupload",
                                      "sop"
                                    )
                                  );
                                  setStatusVerify((prevStatus) => ({
                                    ...prevStatus,
                                    [index]: false
                                  }));
                                }}
                              />
                              <Chip
                                label="Verify"
                                color="success"
                                variant="outlined"
                                style={{
                                  color: "rgba(50, 157, 0, 1)",
                                  borderColor: "rgba(50, 157, 0, 1)",
                                  marginLeft: "10px",
                                  fontSize: "1em",
                                  position: "relative",
                                  zIndex: 2,
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  dispatch(
                                    changeSopApplicationStatusAction(
                                      sopData.leadId,
                                      sopData.countryId,
                                      "verified",
                                      "sop"
                                    )
                                  );
                                  setStatusVerify((prevStatus) => ({
                                    ...prevStatus,
                                    [index]: false
                                  }));
                                }}
                              />

                              <Chip
                                label="close"
                                color="success"
                                variant="outlined"
                                style={{
                                  color: "rgba(227, 71, 43)",
                                  borderColor: "rgba(227, 71, 43)",
                                  marginLeft: "10px",
                                  fontSize: "1em",
                                  position: "relative",
                                  zIndex: 2,
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  setStatusVerify((prevStatus) => ({
                                    ...prevStatus,
                                    [index]: false
                                  }));
                                }}
                              />
                            </>
                          </Box>
                        )}
                      </Box>
                    </div>
                  </div>

                  <div
                    className="rounded-flag"
                    style={{
                      marginLeft: "auto",
                      paddingLeft: "auto",
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end"
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "8px",
                        color: "#329D00",
                        fontWeight: 400,
                        fontFamily: "Montserrat,sans-serif",
                        fontSize: "20px"
                      }}
                    >
                      Uploaded
                    </div>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        color:
                          sopData.status === "verified" ||
                          sopData.status === "approved"
                            ? "rgba(50, 157, 0, 1)"
                            : "rgba(255, 153, 0, 1)"
                      }}
                    >
                      {sopData.status}
                    </Typography>
                  </div>
                </div>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default SopDetails;
