import React, { useEffect, useState } from "react";
import LeadsTable from "../../Table/LeadsTable";

import { useDispatch, useSelector } from "react-redux";

import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  InputAdornment,
  TextField
} from "@mui/material";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import { leadApprovalRequestFindAction, leadPassportEditFindRequestAction } from "../../../actions/leadActions/leadActions";
import LeadApprovalTable from "./leadApprovalTable";
import { RiSearch2Line } from "react-icons/ri";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative"
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));

function LeadPassportEditApprove(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [leadData, setLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [pagiantionEntry, setPagiantionEntry] = useState(5);
  const [searchText, setSearchText] = useState("");

  let { leadPassportEditFindRequestSuccess } = useSelector(
    (state) => {
      return state.leadPassportEditRequestFind;
    }
  );

  let { leadPassportEditApproveRequestSuccess } = useSelector(
    (state) => {
      return state.leadPassportEditRequestApprove;
    }
  );

  let { leadPassportEditRejectRequestSuccess } = useSelector(
    (state) => {
      return state.leadPassportEditRequestReject;
    }
  );

  //   let { loginFindSuccess } = useSelector((state) => {
  //     return state.findUser;
  //   });

  useEffect(() => {
    dispatch(leadPassportEditFindRequestAction());
  }, [dispatch, leadPassportEditApproveRequestSuccess, leadPassportEditRejectRequestSuccess]);

  // material Table
  const columns = [
    {
      title: "Sl no",
      field: "sl"
    },
    {
      title: "Name",
      field: "name"
      // hidden: showNameColumn,
    },
    {
      title: "Email",
      field: "name"
      // hidden: showNameColumn,
    },
    {
      title: "Phone",
      field: "phone"
      // hidden: showPhoneColumn,
    },

    {
      title: "What's App Number",
      field: "whatsappNumber"
      // hidden: showWhatsAppNumColumn,
    },

    {
      title: "Lead Source",
      field: "leadSource"
      // hidden: showLeadSourceColumn,
    },
    {
      title: "Lead Owner",
      field: "leadOwner"
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Updated passport number",
      field: "Updated passport number"
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "UpdatedBy",
      field: "UpdatedBy"
      // hidden: showUniversityPlaceColumn,
    },
    {
      title: "Action",
      field: "action",
      filtering: false
    }
  ];

  // custom hook for privilage check
  const { privilege, hasPrivilege } = usePrivilegeCheck();

  useEffect(() => {
    if (leadPassportEditFindRequestSuccess) {
      setLeadData(leadPassportEditFindRequestSuccess?.data);
    }
  }, [leadPassportEditFindRequestSuccess]);

  useEffect(() => {
    setData(
      leadData?.map((lead, index) => ({
        id: lead.personalDetailsId,
        leadId:lead.leadId,
        sl: index + 1,
        name: lead.lead.firstName,
        email: lead.lead.email,
        phone: lead.lead.mobileNumber,
        whatsappNumber: lead.lead.whatsappNumber,
        leadSource: lead.lead.leadSource,
        leadOwner: lead.lead.leadOwnerName,
        updatedPassportNumber:lead.passportNumber,
        updatedBy: lead.updatedBy,
        status: lead.Status
      }))
    );
  }, [leadData]);


  const searchHandleChange = (e) => {
    let value = e.target.value;
    if (value) {
      let result = leadPassportEditFindRequestSuccess?.data?.filter(
        (item) =>
          item.firstName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item.email?.toLowerCase()?.includes(value?.toLowerCase())||
          item.mobileNumber?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setLeadData(result);
     
    } else {
      setLeadData(leadPassportEditFindRequestSuccess?.data);    }
  };

  console.log(
    leadPassportEditFindRequestSuccess,
    "leadPassportEditFindRequestSuccessleadPassportEditFindRequestSuccess"
  );

  return (
    <>
      <Box
        sx={{
          mt: "30px",
          display: "flex",
          // alignItems: "center",
          justifyContent: "end"
        }}
      >
        <div className={classes.searchContainer}>
          <TextField
            className={classes.searchInput}
            variant="outlined"
            placeholder="Search Here"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              searchHandleChange(e);
            }}
            sx={{
              "& .MuiInputBase-input": {
                borderRadius: "20px",
                height: "1px",
                paddingRight: "48px !important"
              },
              "& .MuiFormLabel-root": {
                lineHeight: "5px"
              },
              "& .MuiInputBase-root": {
                borderRadius: "3px",
                borderColor: "#fafafa"
              },
              "& .MuiOutlinedInput-notchedOutline ": {
                borderRadius: "3px",
                borderColor: "#ECECEC !important",
                borderWidth: "1px !important"
              }
            }}
          />
          <>
            <InputAdornment
              position="end"
              sx={{
                position: "absolute",
                right: "8px",
                width: "40px",
                // top:"0",
                // bottom:"0",
                border: "1px solid #ECECEC",
                borderRadius: "3px",
                height: "34px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer"
              }}
            >
              <RiSearch2Line />
            </InputAdornment>
          </>
        </div>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LeadApprovalTable
            data={data}
            columns={columns}
            component="leadPassportApproval"
            selectBoxIdPassHandler={""}
            pageNumber={"page"}
            subMenu={props.submenu}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LeadPassportEditApprove;
