import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Paper,
  Typography,
  Button,
  Grid,
  Menu,
  MenuItem,
  Divider,
  Tooltip,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Slide,
  InputAdornment,
  IconButton,
  CircularProgress
} from "@mui/material";
import { format } from "date-fns";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Box } from "@mui/system";
import LeaderCreateStepper from "./CreateLeaderStepper";
import { CgArrowsExchange } from "react-icons/cg";
import { IoIosLock } from "react-icons/io";
import OtpTimer from "otp-timer";

import HeadBreadcrumbs from "../../breadcrumbs/breadcrumbs";
import { MainContainer } from "../../content/content.element";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  leadChangeStatusAction,
  leadDetailFetchAction,
  leadDuplicateApprovalFindAction,
  leadDuplicateApprovalFindOneAction,
  leadEmailVerifiedAction,
  leadOtpVerifyAction,
  leadPhoneNumberVerifiedAction,
  leadResendCredentialAction,
  leadSetCredentialAction
} from "../../../actions/leadActions/leadActions";
import Dialogue from "../../customComponent/Dialogue";
import { SubmitButton } from "../../customComponent/Buttons";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { SUCCESS_TRUE_MSG_ERR } from "../../../constants/roleManagmentConstant";
import EditLeadForm from "./EditLeadForm";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck";
import {
  LEAD_COUNSILORNOTE_CREATE_SUCCESS,
  LEAD_EDIT_SUBMIT_SUCCESS,
  LEAD_EMAIL_VERIFIED_ERR,
  LEAD_EMAIL_VERIFIED_SUCCESS,
  LEAD_OTP_VERIFY_ERR,
  LEAD_OTP_VERIFY_SUCCESS,
  LEAD_PERSONAL_DETAILS_ADD_SUCCESS_MESSAGE,
  LEAD_PHONENUMBER_VERIFIED_ERR,
  LEAD_PHONENUMBER_VERIFIED_SUCCESS,
  LEAD_STATUS_SHOW_SUCCESS,
  LEAD_TELECALLERNOTE_CREATE_SUCCESS
} from "../../../constants/leadConstant";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { CiRepeat } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import PremissionDialog from "../../customComponent/premissionDialog";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import AccessDeniedIcon from "./AccessDeniedIcon";
import Loader from "../../customComponent/Loader";

import "./stepper.css";

// dialogue transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateLeader(props) {
  const {
    isUpdatePrevilage,
    isFollowUpPrevilage,
    isTimeLinePrevilage,
    isLeadStatusChangePrevilage,
    isCredentialActionPrevilage,
    isPaymentDetailsPrevilage
  } = usePrivilegeCheck();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, subMenu, mainMenu, tab } = useParams();
  const [leadDetail, setLeadDetail] = useState();
  const [counsilorNote, setCounsilorNote] = useState([]);
  const [teleCallerNote, setTelecallerNote] = useState([]);
  const [marketingNote, setMarketingNote] = useState([]);

  const [open, setOpen] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [owner, setOwner] = useState({});
  const [reason, setReason] = useState(false);
  const [deferalReason, setDeferalReason] = useState(false);
  const [status, setStatus] = useState("");
  const [openSetPassword, setOpenSetPassword] = useState(false);
  const [openOtpPopUp, setOpenOtpPopUp] = useState(false);
  const [openOtpLoading, setOpenOtpLoading] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [noteAddPopUp, setNoteAddpopUp] = useState("");
  const [noteViewPopUp, setNoteViewpopUp] = useState("");
  const [isLeadLocked, setIsLeadLocked] = useState(false);
  const [leadLockedPopUp, setLeadLockedPopUp] = useState(false);
  const [leadLockedPopUpMsg, setLeadLockedPopUpMsg] = useState("");
  const [view, setView] = useState(false);
  const [confirmview, setConfirmView] = useState(false);
  const [resendButton, setResendButton] = useState(false);

  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState(false);
  const [otp, setOTP] = useState("");
  const [verifiedNumber, setVerifiedNumber] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [verificationType, setVerficationType] = useState("");
  const inputRef = useRef(null);

  let {
    leadDuplicateApprovalFindOneSuccess,
    leadDuplicateApprovalFindOneError
  } = useSelector((state) => {
    return state.leadDuplicateApprovalFindOne;
  });

  let { leadDetailLoading, leadDetailSuccess } = useSelector((state) => {
    return state.leadDetailFetch;
  });

  let { leadStatusChangeSuccess, leadStatusChangeError } = useSelector(
    (state) => {
      return state.leadChangeStatus;
    }
  );

  let { leadOwnerSuccess } = useSelector((state) => {
    return state.leadOwnerChange;
  });

  //CounsilorNote create success

  let { leadCounsilorNoteCreateSuccess, leadCounsilorNoteCreateError } =
    useSelector((state) => {
      return state.leadCounsilorNoteCreate;
    });

  let { leadTelecallerNoteSuccess, leadTelecallerNoteError } = useSelector(
    (state) => {
      return state.leadTelecallerNoteCreate;
    }
  );

  let { leadMarketingNoteSuccess, leadMarketingNoteError } = useSelector(
    (state) => {
      return state.leadMarketingNoteCreate;
    }
  );

  const { successAlertMsgSuccess, successAlertMsgErr } = useSelector(
    (state) => {
      return state.successTrueMsg;
    }
  );

  const { leadEditSuccess } = useSelector((state) => {
    return state.leadEdit;
  });

  let { loginFindSuccess } = useSelector((state) => {
    return state.findUser;
  });

  let {
    leadPersonalDetailAddSuccessMsgSuccess,
    leadPersonalDetailAddErrorMsgerror,
    leadPersonalDetailAddSuccess,
    leadPersonalDetailAdderror
  } = useSelector((state) => {
    return state.leadPersonalDetailsAddReducer;
  });

  let {
    leadPhoneNumberVerifiedSuccess,
    leadPhoneNumberVerifiedLoading,
    leadPhoneNumberVerifiedError
  } = useSelector((state) => {
    return state.leadPhoneNumberVerified;
  });

  let {
    leadEmailVerifiedSuccess,
    leadEmailVerifiedLoading,
    leadEmailVerifiedError
  } = useSelector((state) => {
    return state.leadEmailVerified;
  });

  let { leadOtpVerifyLoading, leadOtpVerifySuccess, leadOtpVerifyError } =
    useSelector((state) => {
      return state.leadOtpVerified;
    });

  useEffect(() => {
    if (leadPhoneNumberVerifiedSuccess || leadEmailVerifiedSuccess) {
      setVerficationType(
        leadPhoneNumberVerifiedSuccess
          ? "phoneNumber"
          : leadEmailVerifiedSuccess
          ? "email"
          : ""
      );
      setOpenOtpLoading(false);
      dispatch({ type: LEAD_PHONENUMBER_VERIFIED_SUCCESS, payload: false });
    }
    // if (leadPhoneNumberVerifiedLoading || leadEmailVerifiedLoading) {
    //   setOpenOtpLoading(true);
    //   dispatch({ type: LEAD_EMAIL_VERIFIED_SUCCESS, payload: false });
    // }
    if (leadPhoneNumberVerifiedError || leadEmailVerifiedError) {
      Swal.fire(
        "Error!",
        `${leadPhoneNumberVerifiedError || leadEmailVerifiedError?.message}`,
        "success"
      ).then((value) => {
        dispatch({ type: LEAD_EMAIL_VERIFIED_ERR, payload: false });
        dispatch({ type: LEAD_PHONENUMBER_VERIFIED_ERR, payload: false });
      });
    }
  }, [
    leadPhoneNumberVerifiedSuccess,
    leadEmailVerifiedSuccess,
    leadPhoneNumberVerifiedLoading,
    leadEmailVerifiedLoading
  ]);

  useEffect(() => {
    if (loginFindSuccess) {
      setOwner(loginFindSuccess);
    }
  }, [loginFindSuccess]);
  useEffect(() => {
    if (successAlertMsgSuccess && leadEditSuccess?.status === "leadUpdated") {
      Swal.fire("Good job!", `User profile edited`, "success").then((value) => {
        dispatch({ type: LEAD_EDIT_SUBMIT_SUCCESS, payload: false });
      });
      dispatch(successTrueMsgAction(false));
    }
    if (
      successAlertMsgSuccess &&
      leadEditSuccess?.status == "waitingForApproval"
    ) {
      Swal.fire(
        "Good job!",
        `user profile Updated and waiting for approval`,
        "warning"
      ).then((value) => {
        dispatch({ type: LEAD_EDIT_SUBMIT_SUCCESS, payload: false });
      });
      dispatch(successTrueMsgAction(false));
    }
  }, [successAlertMsgSuccess, dispatch, leadEditSuccess]);

  useEffect(() => {
    if (successAlertMsgSuccess && leadStatusChangeSuccess) {
      Swal.fire("Success!", leadStatusChangeSuccess?.message, "success");
      dispatch({ type: LEAD_STATUS_SHOW_SUCCESS, payload: false });
      dispatch(successTrueMsgAction(false));
    }
  }, [successAlertMsgSuccess, dispatch, leadStatusChangeSuccess]);

  useEffect(() => {
    if (successAlertMsgErr && leadStatusChangeError) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: leadStatusChangeError
      });
      dispatch({ type: SUCCESS_TRUE_MSG_ERR, payload: false });
    }
  }, [successAlertMsgErr, leadStatusChangeError, dispatch]);

  // Counsilor note Successmsg

  useEffect(() => {
    if (leadCounsilorNoteCreateSuccess) {
      Swal.fire("success", "Counsilor note added successfully", "success");
      dispatch({ type: LEAD_COUNSILORNOTE_CREATE_SUCCESS, payload: false });
    }
    if (leadTelecallerNoteSuccess) {
      Swal.fire("success", "Telecaller note added successfully", "success");
      dispatch({ type: LEAD_TELECALLERNOTE_CREATE_SUCCESS, payload: false });
    }

    if (leadOtpVerifySuccess) {
      setOpenOtpPopUp(false);
      Swal.fire("success", "Otp verified successfully", "success");
      dispatch({ type: LEAD_OTP_VERIFY_SUCCESS, payload: false });
    }
    if (leadOtpVerifyError) {
      Swal.fire("Error", `${leadOtpVerifyError?.message}`, "warning");
      dispatch({ type: LEAD_OTP_VERIFY_ERR, payload: false });
    }
  }, [
    leadCounsilorNoteCreateSuccess,
    leadTelecallerNoteSuccess,
    leadOtpVerifySuccess,
    leadOtpVerifyError
  ]);



  useEffect(() => {
    if (leadDetailSuccess) {
      setLeadDetail(leadDetailSuccess?.leadDetails);
      setIsLeadLocked(
        leadDetailSuccess?.leadDetails?.isLeadDuplicate ||
          leadDetailSuccess?.leadDetails?.ownerChangeApproval ||
          leadDetailSuccess?.leadDetails?.leadEmailApproval ||
          leadDetailSuccess?.leadDetails?.leadSourceApproval ||
          leadDetailSuccess?.leadDetails?.leadPhoneNumberApproval ||
          leadDetailSuccess?.leadDetails?.isPassportVerificationRequest
        // leadDetailSuccess?.leadDetails?.leadPassportEdit
      );
      setCounsilorNote(leadDetailSuccess?.CounsilorNote);
      setTelecallerNote(leadDetailSuccess?.teleCallerNote);
      setMarketingNote(leadDetailSuccess?.marketingNote);
    }
    if (
      leadDetailSuccess?.leadDetails?.isLeadDuplicate ||
      leadDetailSuccess?.leadDetails?.ownerChangeApproval ||
      leadDetailSuccess?.leadDetails?.leadEmailApproval ||
      leadDetailSuccess?.leadDetails?.leadSourceApproval ||
      leadDetailSuccess?.leadDetails?.leadPhoneNumberApproval ||
      leadDetailSuccess?.leadDetails?.isPassportVerificationRequest
      // leadDetailSuccess?.leadDetails?.leadPassportEdit
    ) {
      setLeadLockedPopUp(true);
    }

    if(leadDetailSuccess?.leadDetails?.isLeadDuplicate){
      setLeadLockedPopUpMsg("Lead is on Lock due to passport duplication approval pending")
    }
    if(leadDetailSuccess?.leadDetails?.ownerChangeApproval){
      setLeadLockedPopUpMsg("Lead is on Lock due to owner change approval pending")

    }
    if(leadDetailSuccess?.leadDetails?.leadEmailApproval){
      setLeadLockedPopUpMsg("Lead is on Lock due to email change approval pending")

    }
    if(leadDetailSuccess?.leadDetails?.leadSourceApproval){
      setLeadLockedPopUpMsg("Lead is on Lock due to source change approval pending")

    }
    if(leadDetailSuccess?.leadDetails?.leadPhoneNumberApproval){
      setLeadLockedPopUpMsg("Lead is on Lock due to mobile number change approval pending")

    }
    if(leadDetailSuccess?.leadDetails?.isPassportVerificationRequest){
      setLeadLockedPopUpMsg("Lead is on Lock due to passport verification pending")
    }

  }, [leadDetailSuccess]);

  useEffect(() => {
    dispatch(leadDetailFetchAction(id));
    dispatch(leadDuplicateApprovalFindOneAction(id));
  }, [
    dispatch,
    leadStatusChangeSuccess,
    id,
    leadOwnerSuccess,
    leadEditSuccess,
    leadCounsilorNoteCreateSuccess,
    leadTelecallerNoteSuccess,
    leadMarketingNoteSuccess,
    leadPersonalDetailAdderror,
    leadPersonalDetailAddSuccess,
    leadOtpVerifySuccess
  ]);

  console.log(
    leadDetailSuccess,
    "leadDetailSuccessleadDetailSuccessleadDetailSuccess"
  );

  console.log(leadLockedPopUp, "leadLockedPopUpleadLockedPopUp");
  console.log(isLeadLocked, "isLeadLockedisLeadLockedisLeadLocked");

  const handleStateChange = (status) => {
    setEditForm(status);
  };
  // button Action
  const [actionShow, setActionShow] = React.useState(null);
  const openAction = Boolean(actionShow);

  // Credential Action
  const [credentialActionShow, setCredentialActionShow] = React.useState(null);
  const openCredentialAction = Boolean(credentialActionShow);

  const handleClickAction = (event) => {
    if (isLeadLocked == true) {
      setLeadLockedPopUp(true);
    } else {
      setActionShow(event.currentTarget);
    }
  };
  const handleCloseAction = () => {
    setActionShow(null);
  };

  const handleStatusChangeClick = (status) => {
    if (leadDetail?.status === "deferral") {
      Swal.fire("You cannot change status here", "warning");
    } else if (status === "dead") {
      setReason(true);
      setStatus(status);
    } else if (status == "deferral") {
      // setReason(true);
      setDeferalReason(true);
      setStatus(status);
    } else if (status == "Drop") {
      setReason(true);
      setStatus(status);
      // } else if (status == "cold") {
      //   setReason(true);
      //   setStatus(status);
    } else {
      dispatch(leadChangeStatusAction(status, id, "", mainMenu));
    }
    handleCloseAction();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseReason = () => {
    setReason(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    dispatch(leadSetCredentialAction(newPassword, id));
    Swal.fire("Success!", "Password set Successfully.", "success");
    setNewPassword("");
    setConfirmPassword("");
    // Reset error
    setError("");
    setOpenSetPassword(false);
  };

  // PhoneNumber verified function

  const handlePhoneNumebrVerify = () => {
    setOpenOtpPopUp(true);
    setVerficationType("phoneNumber");
    dispatch(leadPhoneNumberVerifiedAction(id));
  };

  // Email verified function

  const handleEmailVerify = () => {
    setOpenOtpPopUp(true);
    setVerficationType("email");
    dispatch(leadEmailVerifiedAction(id));
  };

  // const handleCounsilorNoteCreate = ()=>{
  //   setNoteAddpopUp("counsilorNote")
  // }

  // const ()=>setNoteAddpopUp("teleCallerNote") = ()=>{
  //   setNoteAddpopUp("teleCallerNote")
  // }

  // handle otp number handler
  const handleOtpChange = () => {
    inputRef.current.focus();
  };

  const handleOtpInputChange = (e) => {
    let { value } = e.target;
    if (value.length <= 4) {
      setOTP(value);
      setVerifiedNumber(false);
      // if (value.length === 4) {
      //   // dispatch(phoneNumberVerificationOtpVerificationAction(value));
      //   // dispatch(successTrueMsgAction(true));
      //   setOtpLoading(true);
      //   // otpVerificationDummy();
      // }
      return;
    }
  };

  const handleVerify = () => {
    dispatch(leadOtpVerifyAction(otp, id, verificationType));
  };

  console.log(
    isPaymentDetailsPrevilage(subMenu),
    "paymentDetailsssssssssssssss"
  );

  console.log(leadDetail, "leadDetailleadDetailleadDetail");

  return (
    <>
      <MainContainer active={props.toggle}>
        <Box
          sx={{
            mt: 2,
            mb: 1,
            py: 1,
            "& .selectFormLabelTobeControlled .MuiInputLabel-root[data-shrink='true']":
              {
                transform: "translate(14px, 5px) scale(0.8)",
                bgcolor: "transparent",
                color: "rgba(0, 0, 0, 0.6)"
              },
            "& .selectFormLabelTobeControlled .MuiSelect-select": {
              mt: "18px"
            },
            "& fieldset": {
              display: "none"
            }
          }}
        >
          {openOtpLoading && <Loader />}

          <div
            style={{
              display: "flex",
              justifyContent: "start",
              backgroundColor: "white"
            }}
          >
            <KeyboardBackspaceIcon
              style={{
                margin: "20px",
                paddingTop: "5px",
                fontSize: "xl",
                cursor: "pointer"
              }}
              onClick={() =>
                navigate(tab == "accounts" ? "/accounts" : "/Leads")
              }
            />

            <HeadBreadcrumbs
              head="home"
              sechead="Lead"
              first="Create Lead"
              // main="Creat Lead"
            />
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "10px",
              alignItems: "center"
            }}
          >
            <Typography
              sx={{
                margin: "13px",
                color: "rgba(132, 132, 132, 1)",
                fontWeight: 400,
                fontSize: "15px",
                pt: 0.8
              }}
            >
              {leadDetail &&
                format(
                  new Date(leadDetail?.createdAt.split("T")[0]),
                  "dd / MM / yyyy"
                )}
            </Typography>

            {/* Credential Action button */}

            <Button
              id="demo-positioned-button"
              aria-controls={
                openCredentialAction ? "demo-positioned-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={openCredentialAction ? "true" : undefined}
              onClick={(event) => {
                if (isLeadLocked == true) {
                  setLeadLockedPopUp(true);
                } else {
                  setCredentialActionShow(event.currentTarget);
                }
              }}
              variant="outlined"
              sx={{
                borderColor: "#000",
                size: "10px",
                padding: 1,
                margin: 1,
                color: "#000",
                width: "auto",
                height: "40px",
                fontWeight: 300,
                fontSize: "13px"
              }}
              endIcon={
                <MdOutlineArrowDropDown
                  style={{ fontSize: "16px", fontWeight: "thin" }}
                />
              }
            >
              Credential actions
            </Button>

            <Menu
              variant="outlined"
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={credentialActionShow}
              open={openCredentialAction}
              onClose={() => setCredentialActionShow(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              PaperProps={{
                sx: {
                  borderColor: "#000",
                  border: 1,
                  mt: 6,
                  width: "auto"
                }
              }}
            >
              <MenuItem>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CiRepeat style={{ fontSize: "12px" }} />
                  <Typography
                    sx={{
                      color: "#656395",
                      fontWeight: 400,
                      fontSize: "12px",
                      pl: 1
                    }}
                    onClick={() => {
                      if (isCredentialActionPrevilage(subMenu)) {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "do You want to approve this ?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, approve it!"
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(leadResendCredentialAction(id));
                            Swal.fire(
                              "Resend!",
                              "Credentials Resend Successfully.",
                              "success"
                            );
                          }
                        });
                      } else {
                        setOpenAlert(true);
                      }
                    }}
                  >
                    Re-send credentials
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem>
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    if (isCredentialActionPrevilage(subMenu)) {
                      setOpenSetPassword(true);
                    } else {
                      setOpenAlert(true);
                    }
                  }}
                >
                  <IoIosAdd style={{ fontSize: "16px" }} />
                  <Typography
                    sx={{
                      color: "#656395",
                      fontWeight: 400,
                      fontSize: "12px",
                      pl: 1
                    }}
                  >
                    Set new password
                  </Typography>
                </Box>
              </MenuItem>
            </Menu>

            {/* Action Button */}

            {isLeadStatusChangePrevilage(subMenu) ? (
              <div>
                <Button
                  id="demo-positioned-button"
                  aria-controls={
                    openAction ? "demo-positioned-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openAction ? "true" : undefined}
                  onClick={handleClickAction}
                  variant="outlined"
                  sx={{
                    borderColor: "#000",
                    size: "10px",
                    padding: 1,
                    margin: 1,
                    color: "#000",
                    width: "auto",
                    height: "40px",
                    fontWeight: 300,
                    fontSize: "13px"
                  }}
                  endIcon={
                    <CgArrowsExchange
                      style={{ fontSize: "16px", fontWeight: "thin" }}
                    />
                  }
                  startIcon={
                    (leadDetail?.status === "new" && "🟠") ||
                    (leadDetail?.status === "future" && "🟠") ||
                    (leadDetail?.status === "warm" && "🟢") ||
                    (leadDetail?.status === "cold" && "🔵") ||
                    (leadDetail?.status === "dead" && "⚪") ||
                    (leadDetail?.status === "Application Started" && "⚪") ||
                    (leadDetail?.status === "deferral" && "🟡") ||
                    (leadDetail?.status === "Drop" && "🔴") ||
                    (leadDetail?.status === "Enrolled" && "🟠") ||
                    (leadDetail?.status === "Recalled" && "🔵")
                  }
                >
                  {leadDetail?.status === "new" && "New"}
                  {leadDetail?.status === "future" && "Future"}
                  {leadDetail?.status === "warm" && "Warm"}
                  {leadDetail?.status === "cold" && "Cold"}
                  {leadDetail?.status === "dead" && "Dead"}
                  {leadDetail?.status === "Application Started" &&
                    "Application Started"}
                  {leadDetail?.status === "deferral" && "Deferral"}
                  {leadDetail?.status === "Drop" && "Drop"}
                  {leadDetail?.status === "Enrolled" && "Enrolled"}
                  {leadDetail?.status === "Recalled" && "Recalled"}
                </Button>

                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={actionShow}
                  open={openAction}
                  onClose={handleCloseAction}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  sx={{
                    borderColor: "black",
                    borderRadius: "24px",
                    mt: 6,
                    width: "auto"
                  }}
                >
                  {mainMenu == "Leads" ||
                  mainMenu == "Deals" ||
                  mainMenu == "drop" ? (
                    <>
                      {mainMenu !== "Deals" && (
                        <>
                          <MenuItem>
                            <Button
                              startIcon={"🟠"}
                              onClick={() => handleStatusChangeClick("new")}
                              sx={{
                                backgroundColor:
                                  leadDetail?.status === "new"
                                    ? "#141E3C"
                                    : null,
                                width: "auto",
                                paddingLeft: "auto",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "12px",
                                fontWeight: 400,
                                color:
                                  leadDetail?.status === "new"
                                    ? "white"
                                    : "black"
                              }}
                            >
                              New
                            </Button>
                          </MenuItem>
                          <MenuItem>
                            <Button
                              startIcon={"🟠"}
                              onClick={() => handleStatusChangeClick("future")}
                              sx={{
                                backgroundColor:
                                  leadDetail?.status === "future"
                                    ? "#141E3C"
                                    : null,
                                width: "auto",
                                paddingLeft: "auto",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "12px",
                                fontWeight: 400,
                                color:
                                  leadDetail?.status === "future"
                                    ? "white"
                                    : "black"
                              }}
                            >
                              Future
                            </Button>
                          </MenuItem>
                          <MenuItem>
                            <Button
                              startIcon={"🟢"}
                              onClick={() => handleStatusChangeClick("warm")}
                              sx={{
                                backgroundColor:
                                  leadDetail?.status === "warm"
                                    ? "#141E3C"
                                    : null,
                                width: "auto",
                                paddingLeft: "auto",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "12px",
                                fontWeight: 400,
                                color:
                                  leadDetail?.status === "warm"
                                    ? "white"
                                    : "black"
                              }}
                            >
                              Warm
                            </Button>
                          </MenuItem>
                          <MenuItem>
                            <Button
                              startIcon={"🔵"}
                              onClick={() => handleStatusChangeClick("cold")}
                              sx={{
                                backgroundColor:
                                  leadDetail?.status === "cold"
                                    ? "#141E3C"
                                    : null,
                                width: "auto",
                                paddingLeft: "auto",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "12px",
                                fontWeight: 400,
                                color:
                                  leadDetail?.status === "cold"
                                    ? "white"
                                    : "black"
                              }}
                            >
                              Cold
                            </Button>
                          </MenuItem>
                          <MenuItem>
                            <Button
                              startIcon={"⚪"}
                              onClick={() => handleStatusChangeClick("dead")}
                              sx={{
                                backgroundColor:
                                  leadDetail?.status === "dead"
                                    ? "#141E3C"
                                    : null,
                                width: "auto",
                                paddingLeft: "auto",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "12px",
                                fontWeight: 400,
                                color:
                                  leadDetail?.status === "dead"
                                    ? "white"
                                    : "black"
                              }}
                            >
                              Dead
                            </Button>
                          </MenuItem>
                        </>
                      )}

                      {mainMenu == "drop" && (
                        <MenuItem>
                          <Button
                            startIcon={"🔴"}
                            onClick={() => handleStatusChangeClick("Drop")}
                            sx={{
                              backgroundColor:
                                leadDetail?.status === "Drop"
                                  ? "#141E3C"
                                  : null,
                              width: "auto",
                              paddingLeft: "auto",
                              display: "flex",
                              justifyContent: "flex-start",
                              fontSize: "12px",
                              fontWeight: 400,
                              color:
                                leadDetail?.status === "Drop"
                                  ? "white"
                                  : "black"
                            }}
                          >
                            Drop
                          </Button>
                        </MenuItem>
                      )}

                      {mainMenu == "Deals" && (
                        <>
                          <MenuItem>
                            <Button
                              startIcon={"⚪"}
                              onClick={() =>
                                handleStatusChangeClick("Application Started")
                              }
                              sx={{
                                backgroundColor:
                                  leadDetail?.status === "Application Started"
                                    ? "#141E3C"
                                    : null,
                                width: "auto",
                                paddingLeft: "auto",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "12px",
                                fontWeight: 400,
                                color:
                                  leadDetail?.status === "Application Started"
                                    ? "white"
                                    : "black"
                              }}
                            >
                              Application Started
                            </Button>
                          </MenuItem>

                          <MenuItem>
                            <Button
                              startIcon={"🔴"}
                              onClick={() => handleStatusChangeClick("Drop")}
                              sx={{
                                backgroundColor:
                                  leadDetail?.status === "Drop"
                                    ? "#141E3C"
                                    : null,
                                width: "auto",
                                paddingLeft: "auto",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "12px",
                                fontWeight: 400,
                                color:
                                  leadDetail?.status === "Drop"
                                    ? "white"
                                    : "black"
                              }}
                            >
                              Drop
                            </Button>
                          </MenuItem>

                          <MenuItem>
                            <Button
                              startIcon={"🟡"}
                              onClick={() =>
                                handleStatusChangeClick("deferral")
                              }
                              sx={{
                                backgroundColor:
                                  leadDetail?.status === "deferral"
                                    ? "#141E3C"
                                    : null,
                                width: "auto",
                                paddingLeft: "auto",
                                display: "flex",
                                justifyContent: "flex-start",
                                fontSize: "12px",
                                fontWeight: 400,
                                color:
                                  leadDetail?.status === "deferral"
                                    ? "white"
                                    : "black"
                              }}
                            >
                              Deferral
                            </Button>
                          </MenuItem>

                          {/* <MenuItem>
                              <Button
                                startIcon={"🟠"}
                                onClick={() =>
                                  handleStatusChangeClick("Enrolled")
                                }
                                sx={{
                                  backgroundColor:
                                    leadDetail?.status === "Enrolled"
                                      ? "#141E3C"
                                      : null,
                                  width: "auto",
                                  paddingLeft: "auto",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  color:
                                    leadDetail?.status === "Enrolled"
                                      ? "white"
                                      : "black"
                                }}
                              >
                                Enrolled
                              </Button>
                            </MenuItem> */}
                        </>
                      )}
                    </>
                  ) : (
                    <MenuItem>
                      <Button
                        startIcon={"🟠"}
                        onClick={() => handleStatusChangeClick("Enrolled")}
                        sx={{
                          backgroundColor:
                            leadDetail?.status === "Enrolled"
                              ? "#141E3C"
                              : null,
                          width: "auto",
                          paddingLeft: "auto",
                          display: "flex",
                          justifyContent: "flex-start",
                          fontSize: "12px",
                          fontWeight: 400,
                          color:
                            leadDetail?.status === "Enrolled"
                              ? "white"
                              : "black"
                        }}
                      >
                        Enrolled
                      </Button>
                    </MenuItem>
                  )}

                  {/* {!owner?.isTeleCaller && (
                  <>
                    {" "}
                    <MenuItem>
                      <Button
                        startIcon={"⚪"}
                        onClick={() =>
                          handleStatusChangeClick("Application Started")
                        }
                        sx={{
                          backgroundColor:
                            leadDetail?.status === "Application Started"
                              ? "#141E3C"
                              : null,
                          width: "auto",
                          paddingLeft: "auto",
                          display: "flex",
                          justifyContent: "flex-start",
                          fontSize: "12px",
                          fontWeight: 400,
                          color:
                            leadDetail?.status === "Application Started"
                              ? "white"
                              : "black"
                        }}
                      >
                        Application Started
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        startIcon={"🔴"}
                        onClick={() => handleStatusChangeClick("Drop")}
                        sx={{
                          backgroundColor:
                            leadDetail?.status === "Drop" ? "#141E3C" : null,
                          width: "auto",
                          paddingLeft: "auto",
                          display: "flex",
                          justifyContent: "flex-start",
                          fontSize: "12px",
                          fontWeight: 400,
                          color:
                            leadDetail?.status === "Drop" ? "white" : "black"
                        }}
                      >
                        Drop
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        startIcon={"🔴"}
                        onClick={() => {
                          setActionShow(null);
                          Swal.fire({
                            title: "Are you sure?",
                            text: "Do you want to switch to deferral!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, switch it!"
                          }).then((result) => {
                            if (result.isConfirmed) {
                              handleStatusChangeClick("deferral");
                            }
                          });
                        }}
                        sx={{
                          backgroundColor:
                            leadDetail?.status === "deferral"
                              ? "#141E3C"
                              : null,
                          width: "auto",
                          paddingLeft: "auto",
                          display: "flex",
                          justifyContent: "flex-start",
                          fontSize: "12px",
                          fontWeight: 400,
                          color:
                            leadDetail?.status === "deferral"
                              ? "white"
                              : "black"
                        }}
                      >
                        Deferral
                      </Button>
                    </MenuItem>{" "}
                  </>
                )} */}
                </Menu>
              </div>
            ) : (
              ""
            )}

            {owner?.firstName == leadDetail?.counsilorID && (
              <Tooltip
                title="Chat"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& svg": {
                    margin: "13px"
                  }
                }}
                onClick={() => navigate(`/Leads/details/chat/${id}`)}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ cursor: "pointer" }}
                >
                  <g clip-path="url(#clip0_9031_90863)">
                    <path
                      d="M19.9431 9.61716C25.4209 9.53349 29.8918 13.8449 29.9979 19.3223C30.0134 20.1265 29.9331 20.9092 29.7675 21.66C29.487 22.9313 29.3203 24.225 29.3203 25.5269V28.0434C29.3203 28.4289 29.0078 28.7415 28.6223 28.7415H26.1058C24.8039 28.7415 23.5102 28.9082 22.2389 29.1886C21.4881 29.3542 20.7054 29.4346 19.9012 29.419C14.4238 29.313 10.1125 24.8422 10.196 19.3645C10.2775 14.0178 14.5965 9.69884 19.9431 9.61716Z"
                      fill="url(#paint0_linear_9031_90863)"
                    />
                    <path
                      d="M19.9431 9.61716C25.4209 9.53349 29.8918 13.8449 29.9979 19.3223C30.0134 20.1265 29.9331 20.9092 29.7675 21.66C29.487 22.9313 29.3203 24.225 29.3203 25.5269V28.0434C29.3203 28.4289 29.0078 28.7415 28.6223 28.7415H26.1058C24.8039 28.7415 23.5102 28.9082 22.2389 29.1886C21.4881 29.3542 20.7054 29.4346 19.9012 29.419C14.4238 29.313 10.1125 24.8422 10.196 19.3645C10.2775 14.0178 14.5965 9.69884 19.9431 9.61716Z"
                      fill="url(#paint1_linear_9031_90863)"
                    />
                    <path
                      d="M12.2518 0.580561C5.57837 0.478608 0.131564 5.731 0.00230621 12.404C-0.0166195 13.3836 0.0812904 14.3374 0.283087 15.252C0.624688 16.8007 0.827773 18.3769 0.827773 19.9629V23.0287C0.827773 23.4983 1.20851 23.8791 1.67826 23.8791H4.744C6.33001 23.8791 7.90612 24.0822 9.45493 24.4238C10.3696 24.6256 11.3232 24.7235 12.3029 24.7046C18.9757 24.5754 24.2281 19.1287 24.1264 12.4554C24.027 5.9417 18.7655 0.679994 12.2518 0.580561Z"
                      fill="url(#paint2_linear_9031_90863)"
                    />
                    <path
                      d="M7.74591 11.9965C7.40513 11.6344 6.92279 11.407 6.38619 11.407C5.35418 11.407 4.51758 12.2436 4.51758 13.2756C4.51758 13.8121 4.74492 14.2945 5.10709 14.6353L9.13798 18.6662C9.47882 19.0283 9.96116 19.2557 10.4977 19.2557C11.5297 19.2557 12.3663 18.4191 12.3663 17.3871C12.3663 16.8506 12.1389 16.3682 11.7768 16.0274L7.74591 11.9965Z"
                      fill="url(#paint3_linear_9031_90863)"
                    />
                    <path
                      d="M6.3857 15.144C7.4177 15.144 8.25431 14.3074 8.25431 13.2753C8.25431 12.2433 7.4177 11.4067 6.3857 11.4067C5.35369 11.4067 4.51709 12.2433 4.51709 13.2753C4.51709 14.3074 5.35369 15.144 6.3857 15.144Z"
                      fill="white"
                    />
                    <path
                      d="M13.0301 11.9965C12.6893 11.6344 12.207 11.407 11.6704 11.407C10.6384 11.407 9.80176 12.2436 9.80176 13.2756C9.80176 13.8121 10.0291 14.2945 10.3913 14.6353L14.4222 18.6662C14.763 19.0283 15.2453 19.2557 15.7819 19.2557C16.8139 19.2557 17.6505 18.4191 17.6505 17.3871C17.6505 16.8506 17.4231 16.3682 17.061 16.0274L13.0301 11.9965Z"
                      fill="url(#paint4_linear_9031_90863)"
                    />
                    <path
                      d="M11.6704 15.144C12.7024 15.144 13.539 14.3074 13.539 13.2753C13.539 12.2433 12.7024 11.4067 11.6704 11.4067C10.6384 11.4067 9.80176 12.2433 9.80176 13.2753C9.80176 14.3074 10.6384 15.144 11.6704 15.144Z"
                      fill="white"
                    />
                    <path
                      d="M18.3148 11.9965C17.974 11.6344 17.4916 11.407 16.955 11.407C15.923 11.407 15.0864 12.2436 15.0864 13.2756C15.0864 13.8121 15.3138 14.2945 15.6759 14.6353L19.7068 18.6662C20.0477 19.0283 20.53 19.2557 21.0666 19.2557C22.0986 19.2557 22.9352 18.4191 22.9352 17.3871C22.9352 16.8506 22.7078 16.3682 22.3456 16.0274L18.3148 11.9965Z"
                      fill="url(#paint5_linear_9031_90863)"
                    />
                    <path
                      d="M16.9555 15.144C17.9875 15.144 18.8241 14.3074 18.8241 13.2753C18.8241 12.2433 17.9875 11.4067 16.9555 11.4067C15.9235 11.4067 15.0869 12.2433 15.0869 13.2753C15.0869 14.3074 15.9235 15.144 16.9555 15.144Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_9031_90863"
                      x1="18.0983"
                      y1="17.5201"
                      x2="32.6368"
                      y2="32.0586"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#A7F3CE" />
                      <stop offset="1" stopColor="#61DB99" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_9031_90863"
                      x1="22.8575"
                      y1="22.279"
                      x2="16.6823"
                      y2="16.1044"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#61DB99" stop-opacity="0" />
                      <stop offset="1" stopColor="#009E74" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_9031_90863"
                      x1="7.6564"
                      y1="10.6926"
                      x2="23.006"
                      y2="26.0423"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#62E1FB" />
                      <stop offset="1" stopColor="#00A2F3" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_9031_90863"
                      x1="10.5429"
                      y1="17.4327"
                      x2="2.46112"
                      y2="9.35114"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#00A2F3" stop-opacity="0" />
                      <stop offset="1" stopColor="#0075CD" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear_9031_90863"
                      x1="15.8267"
                      y1="17.4325"
                      x2="7.74517"
                      y2="9.35094"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#00A2F3" stop-opacity="0" />
                      <stop offset="1" stopColor="#0075CD" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear_9031_90863"
                      x1="21.1114"
                      y1="17.4326"
                      x2="13.0298"
                      y2="9.35103"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#00A2F3" stop-opacity="0" />
                      <stop offset="1" stopColor="#0075CD" />
                    </linearGradient>
                    <clipPath id="clip0_9031_90863">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Tooltip>
            )}
            {isTimeLinePrevilage(subMenu) ? (
              <Tooltip title="Time line">
                <img
                  src="/menuIcons/refresh.png"
                  alt="gsl"
                  style={{
                    width: "30px",
                    height: "auto",
                    margin: "13px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (isLeadLocked == true) {
                      setLeadLockedPopUp(true);
                    } else {
                      navigate(`/Administration/employee/details/track/${id}`);
                    }
                  }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>

          {/* <Paper
            elevation={0}
            sx={{ height: "168px", border: "0.5px solid #ECECEC", mt: 2 }}
          >
            <Grid container spacing={2}>
              <Grid item lg={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      margin: "15px"
                    }}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        leadDetail?.imgUrl
                          ? leadDetail?.imgUrl
                          : "/profileAvatar.png"
                      }
                      sx={{ width: 120, height: 121, margin: "10px" }}
                    />

                    <div
                      style={{
                        marginTop: "15px",
                        marginLeft: "15px"
                      }}
                    >
                      <div>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            fontFamily: "'Open Sans', sans-serif",
                            color: "rgba(172, 177, 198, 1)"
                          }}
                        >
                          {leadDetail?.leadId ? leadDetail?.leadId : "-"}
                        </Typography>
                        <div
                          style={{
                            marginTop: "-6px",
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            marginBottom: "5px"
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 700,
                              fontSize: "23px",
                              fontFamily: "'Montserrat', sans-serif"
                            }}
                          >
                            {leadDetail?.firstName} {leadDetail?.lastName}
                            &nbsp;
                            {isUpdatePrevilage(subMenu) ? (
                              <ModeEditOutlineIcon
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  padingTop: "8px"
                                }}
                                fontSize="medium"
                                onClick={() => {
                                  if (isLeadLocked == true) {
                                    setLeadLockedPopUp(true);
                                  } else {
                                    setEditForm(true);
                                  }
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Typography>{" "}
                          {leadDetail?.isLeadDuplicate == true && (
                            <IoIosLock size={24} color="red" />
                          )}
                        </div>
                        {leadDetail?.isLeadDuplicate == true && (
                          <div style={{ marginTop: "-6px" }}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: 400,
                                fontSize: "15px",
                                fontFamily: "'Montserrat', sans-serif",
                                color: "red"
                              }}
                            >
                              {leadDetail?.isLeadDuplicate == true
                                ? "Duplicate"
                                : ""}
                            </Typography>{" "}
                          </div>
                        )}

                        {leadDuplicateApprovalFindOneSuccess?.data?.Status ==
                          "Approved" && (
                          <div style={{ marginTop: "-6px" }}>
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: 400,
                                fontSize: "15px",
                                fontFamily: "'Montserrat', sans-serif",
                                color: "red"
                              }}
                            >
                              {`Duplicate entry approved by ${leadDuplicateApprovalFindOneSuccess?.data?.updatedBy}`}
                            </Typography>{" "}
                          </div>
                        )}

                        <div style={{ marginTop: "-6px" }}>
                          <p
                            style={{
                              fontWeight: 400,
                              fontSize: "13px",
                              fontFamily: "'Open Sans', sans-serif"
                            }}
                          >
                            {leadDetail &&
                              format(
                                new Date(leadDetail?.dob),
                                "dd / MM / yyyy"
                              )}
                          </p>

                          <Box sx={{ display: "flex" }}>
                            {isFollowUpPrevilage(subMenu) ? (
                              <SubmitButton
                                title="Follow up"
                                submit=""
                                widthSize="110px"
                                heightSize="35px"
                                type="click"
                                handleSubmit={() => {
                                  if (isLeadLocked == true) {
                                    setLeadLockedPopUp(true);
                                  } else {
                                    navigate(
                                      `/Leads/details/leadFollowUp/${id}`
                                    );
                                  }
                                }}
                              />
                            ) : (
                              ""
                            )}
                            <Box sx={{ ml: 1 }}>
                              {isPaymentDetailsPrevilage(subMenu) ? (
                                <SubmitButton
                                  title="Payment details"
                                  submit=""
                                  widthSize="150px"
                                  heightSize="35px"
                                  type="click"
                                  handleSubmit={() => {
                                    if (isLeadLocked == true) {
                                      setLeadLockedPopUp(true);
                                    } else {
                                      navigate(`/paymentHistory/${id}`);
                                    }
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <Divider
                      orientation="vertical"
                      height="110px"
                      sx={{ marginLeft: "60px" }}
                    />
                  </div>

                  <div
                    style={{
                      marginRight: "auto",
                      paddingRight: "auto",
                      marginLeft: "15px"
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Phone
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Email
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Location
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Gender
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Counsilor note
                    </p>
                  </div>

                  <div
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      marginRight: "auto",
                      paddingRight: "auto"
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "rgba(132, 132, 132, 1)",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      {leadDetail?.mobileNumber}
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "rgba(132, 132, 132, 1)",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      {leadDetail?.email}
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "rgba(132, 132, 132, 1)",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      {leadDetail?.location}
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "rgba(132, 132, 132, 1)",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      {leadDetail?.gender}
                    </p>

                    {counsilorNote.length !== 0 ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          fontWeight: 700,
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          if (isLeadLocked == true) {
                            setLeadLockedPopUp(true);
                          } else {
                            setNoteViewpopUp("counsilorNote");
                          }
                        }}
                      >
                        View Notes
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          fontWeight: 700,
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          if (isLeadLocked == true) {
                            setLeadLockedPopUp(true);
                          } else {
                            setNoteAddpopUp("counsilorNote");
                          }
                        }}
                      >
                        + Add
                      </p>
                    )}
                  </div>

                  <div style={{ marginRight: "auto", paddingRight: "auto" }}>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Lead Source
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Counsellor
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Education
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Preferred Country
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif"
                      }}
                    >
                      Telecaller note
                    </p>
                  </div>

                  <div
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      marginRight: "10px"
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "rgba(132, 132, 132, 1)",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      {leadDetail?.leadSource ? leadDetail?.leadSource : "-"}
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "rgba(132, 132, 132, 1)",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      {leadDetail?.counsilorID ? leadDetail?.counsilorID : "-"}
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "rgba(132, 132, 132, 1)",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      {leadDetail?.education ? leadDetail?.education : "-"}
                    </p>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "'Open Sans', sans-serif",
                        color: "rgba(132, 132, 132, 1)",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                    >
                      {leadDetail?.preferredCountryId
                        ? leadDetail?.preferredCountryId
                        : "-"}
                    </p>

                    {teleCallerNote?.length !== 0 ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          fontWeight: 700,
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          if (isLeadLocked == true) {
                            setLeadLockedPopUp(true);
                          } else {
                            setNoteViewpopUp("teleCallerNote");
                          }
                        }}
                      >
                        View note
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          fontWeight: 700,
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          if (isLeadLocked == true) {
                            setLeadLockedPopUp(true);
                          } else {
                            setNoteAddpopUp("teleCallerNote");
                          }
                        }}
                      >
                        + Add
                      </p>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper> */}

          <div style={{ marginBottom: "49px" }}>
            <section className="userInfo">
              <div className="com_Dis com_DisWrapper">
                <div className="w-55p com_Dis">
                  <div className="userInfoC w-45p disColCard">
                    <div className="profilePhoto">
                      <img
                        src={
                          leadDetail?.imgUrl
                            ? leadDetail?.imgUrl
                            : "/profileAvatar.png"
                        }
                        alt=""
                      />
                    </div>
                    <p>{leadDetail?.leadId ? leadDetail?.leadId : "-"}</p>
                    <div className="nameEdit">
                      <h4 className="bold700">
                        {leadDetail?.firstName} {leadDetail?.lastName}
                      </h4>
                      {isUpdatePrevilage(subMenu) ? (
                        <ModeEditOutlineIcon
                          style={{
                            cursor: "pointer",
                            // marginLeft: "5px",
                            padingTop: "8px"
                          }}
                          fontSize="medium"
                          onClick={() => {
                            if (isLeadLocked == true) {
                              setLeadLockedPopUp(true);
                            } else {
                              setEditForm(true);
                            }
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {leadDetail?.isLeadDuplicate == true ||
                      leadDetail?.ownerChangeApproval == true ||
                      leadDetail?.leadEmailApproval == true ||
                      leadDetail?.leadSourceApproval == true ||
                      leadDetailSuccess?.leadDetails
                        ?.isPassportVerificationRequest == true ||
                      leadDetail?.leadPhoneNumberApproval == true ? (
                        <IoIosLock size={24} color="red" />
                      ) : (
                        ""
                      )}
                    </div>
                    {leadDetail?.isLeadDuplicate == true ? (
                      <div style={{ marginTop: "-6px" }}>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: 400,
                            fontSize: "15px",
                            fontFamily: "'Montserrat', sans-serif",
                            color: "red"
                          }}
                        >
                          {leadDetail?.isLeadDuplicate == true
                            ? "Duplicate"
                            : ""}
                        </Typography>{" "}
                      </div>
                    ) : (
                      ""
                    )}

                    {leadDuplicateApprovalFindOneSuccess?.data?.Status ==
                      "Approved" && (
                      <div style={{ marginTop: "-6px" }}>
                        <Typography
                          // variant="h4"
                          sx={{
                            fontWeight: 400,
                            fontSize: "15px",
                            fontFamily: "'Montserrat', sans-serif",
                            color: "red !important"
                          }}
                        >
                          {`Duplicate entry approved by ${leadDuplicateApprovalFindOneSuccess?.data?.updatedBy}`}
                        </Typography>{" "}
                      </div>
                    )}
                    <p className="date">
                      {" "}
                      {leadDetail &&
                        format(new Date(leadDetail?.dob), "dd / MM / yyyy")}
                    </p>
                    <div className="btnWrapper">
                      {isFollowUpPrevilage(subMenu) ? (
                        <SubmitButton
                          title="Follow up"
                          submit=""
                          widthSize="110px"
                          heightSize="35px"
                          type="click"
                          handleSubmit={() => {
                            if (isLeadLocked == true) {
                              setLeadLockedPopUp(true);
                            } else {
                              navigate(`/Leads/details/leadFollowUp/${id}`);
                            }
                          }}
                        />
                      ) : (
                        ""
                      )}

                      <SubmitButton
                        title="Payment Details"
                        submit=""
                        widthSize="150px"
                        heightSize="35px"
                        type="click"
                        handleSubmit={() => {
                          if (isLeadLocked == true) {
                            setLeadLockedPopUp(true);
                          } else {
                            navigate(`/paymentHistory/${id}`);
                          }
                        }}
                      />
                      {/* <Button
                        width="110px"
                        height="35px"
                        type="click"
                        onChange={() => {
                          if (isLeadLocked == true) {
                            setLeadLockedPopUp(true);
                          } else {
                            navigate(`/paymentHistory/${id}`);
                          }
                        }}
                        className="primBtn secoBtn"
                      >
                        Payment Details
                      </Button> */}
                    </div>
                  </div>
                  <div className="userInfoC w-55p disColCard">
                    <ul
                      className="w-100 mb-lg-2 mb-3"
                      style={{ width: "100%" }}
                    >
                      <li>
                        <div className="w-30p semiBold600 d-flex align-items-center">
                          Phone
                          {leadDetail?.phoneNumberVerified ? (
                            <img
                              src="/verified.png"
                              className="img-fluid ms-2"
                              alt=""
                              style={{
                                marginLeft: "5px",
                                marginTop: "1px",
                                cursor: "pointer"
                              }}
                            />
                          ) : (
                            <img
                              src="/NotVerified.png"
                              className="img-fluid ms-2"
                              alt=""
                              style={{
                                marginLeft: "5px",
                                marginTop: "1px",
                                cursor: "pointer"
                              }}
                              onClick={handlePhoneNumebrVerify}
                            />
                          )}
                        </div>
                        <div className="w-70p regular400 lightColor">
                          <span href="">
                            {leadDetail?.countryId || ""}{" "}
                            {leadDetail?.mobileNumber || ""}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="w-30p semiBold600 d-flex align-items-center">
                          Email ID
                          {leadDetail?.emailVerified ? (
                            <img
                              src="/verified.png"
                              className="img-fluid ms-2"
                              alt=""
                              style={{
                                marginLeft: "5px",
                                marginTop: "1px",
                                cursor: "pointer"
                              }}
                            />
                          ) : (
                            <img
                              src="/NotVerified.png"
                              className="img-fluid ms-2"
                              alt=""
                              style={{
                                marginLeft: "5px",
                                marginTop: "1px",
                                cursor: "pointer"
                              }}
                              onClick={handleEmailVerify}
                            />
                          )}
                        </div>
                        <div
                          className="w-70p regular400 lightColor"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}
                        >
                          <sapn href="">{leadDetail?.email}</sapn>
                        </div>
                      </li>
                      <li>
                        <div className="w-30p semiBold600">Passport ID</div>
                        <div className="w-70p regular400 lightColor">
                          <sapn href="">
                            {
                              leadDetail?.lead_personal_details[0]
                                ?.passportNumber
                            }
                          </sapn>
                        </div>
                      </li>
                      <li>
                        <div className="w-30p semiBold600">Gender</div>
                        <div className="w-70p regular400 lightColor">
                          {leadDetail?.gender}
                        </div>
                      </li>
                      <li>
                        <div className="w-30p semiBold600">Location</div>
                        <div className="w-70p regular400 lightColor">
                          {leadDetail?.location}
                        </div>
                      </li>
                      <li>
                        <div className="w-30p semiBold600">Education</div>
                        <div className="w-70p regular400 lightColor">
                          {leadDetail?.education || "-"}
                        </div>
                      </li>
                    </ul>
                    <div
                      className="w-100 com_Dis mt-auto"
                      style={{
                        marginLeft: "-10px",
                        marginTop: "18px",
                        width: "100%"
                      }}
                    >
                      <div className="w-50p userInfoC">
                        <h6 className="font-size-16 semiBold600">
                          Communication Status
                        </h6>
                        <ul className="w-100 mt-3">
                          <li>
                            <div className="w-70p semiBold600">Email Sent</div>
                            <div className="w-30p regular400 lightColor text-end">
                              0
                            </div>
                          </li>
                          <li>
                            <div className="w-70p semiBold600">SMS Sent</div>
                            <div className="w-30p regular400 lightColor text-end">
                              0
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="w-50p userInfoC">
                        <h6 className="font-size-16 semiBold600">
                          Telephony Status
                        </h6>
                        <ul className="w-100 mt-3">
                          <li>
                            <div className="w-70p semiBold600">
                              Incoming Call
                            </div>
                            <div className="w-30p regular400 lightColor text-end">
                              0
                            </div>
                          </li>
                          <li>
                            <div className="w-70p semiBold600">
                              Out Bound Call
                            </div>
                            <div className="w-30p regular400 lightColor text-end">
                              0
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-45p com_Dis">
                  <div className="userInfoC w-55p disColCard">
                    <ul className="w-100 mb-2 type02" style={{ width: "100%" }}>
                      <li>
                        <div className="w-45p semiBold600">Lead Branch</div>
                        <div className="w-55p regular400 lightColor">
                          {leadDetail?.branch_master?.branchName}
                        </div>
                      </li>
                      <li>
                        <div className="w-45p semiBold600">Branch Manager</div>
                        <div className="w-55p regular400 lightColor">
                          {leadDetail?.leadOwnerName}
                        </div>
                      </li>
                      <li>
                        <div className="w-45p semiBold600">Lead Source</div>
                        <div className="w-55p regular400 lightColor">
                          {leadDetail?.leadSource
                            ? leadDetail?.leadSource
                            : "-"}
                        </div>
                      </li>
                      <li>
                        <div className="w-45p semiBold600">
                          Lead Source Name
                        </div>
                        <div className="w-55p regular400 lightColor">
                          {leadDetail?.refno || "-"}
                        </div>
                      </li>
                      <li>
                        <div className="w-45p semiBold600">
                          Preferred Country
                        </div>
                        <div className="w-55p regular400 lightColor">
                          {leadDetail?.preferredCountryId || "-"}
                        </div>
                      </li>
                      <li>
                        <div className="w-45p semiBold600">Counsellor</div>
                        <div className="w-55p regular400 lightColor">
                          {leadDetail?.counsilorID || "-"}
                        </div>
                      </li>
                      <li>
                        <div className="w-45p semiBold600">Counsellor Note</div>
                        <div className="w-55p regular400 lightColor">
                          {counsilorNote.length !== 0 ? (
                            <p
                              style={{
                                color: "#3434e0",
                                fontWeight: 500,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                if (isLeadLocked == true) {
                                  setLeadLockedPopUp(true);
                                } else {
                                  setNoteViewpopUp("counsilorNote");
                                }
                              }}
                            >
                              View Notes
                            </p>
                          ) : (
                            <p
                              style={{
                                color: "#3434e0",
                                fontWeight: 500,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                if (isLeadLocked == true) {
                                  setLeadLockedPopUp(true);
                                } else {
                                  setNoteAddpopUp("counsilorNote");
                                }
                              }}
                            >
                              + Add
                            </p>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="userInfoC w-45p disColCard">
                    <ul
                      className="w-100 mb-2 type02 type03"
                      style={{ width: "100%" }}
                    >
                      <li>
                        <div className="w-65p semiBold600">Tele Caller</div>
                        <div className="w-35p regular400 lightColor">
                          {leadDetail?.teleCallerId || "-"}
                        </div>
                      </li>
                      <li>
                        <div className="w-65p semiBold600">
                          Tele Caller Note
                        </div>
                        <div className="w-35p regular400 lightColor">
                          {teleCallerNote?.length !== 0 ? (
                            <p
                              style={{
                                color: "#3434e0",
                                fontWeight: 500,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                if (isLeadLocked == true) {
                                  setLeadLockedPopUp(true);
                                } else {
                                  setNoteViewpopUp("teleCallerNote");
                                }
                              }}
                            >
                              View note
                            </p>
                          ) : (
                            <p
                              style={{
                                color: "#3434e0",
                                fontWeight: 500,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                if (isLeadLocked == true) {
                                  setLeadLockedPopUp(true);
                                } else {
                                  setNoteAddpopUp("teleCallerNote");
                                }
                              }}
                            >
                              + Add
                            </p>
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="w-65p semiBold600">Marketing Note</div>
                        <div className="w-35p regular400 lightColor">
                          {marketingNote?.length !== 0 ? (
                            <p
                              style={{
                                color: "#3434e0",
                                fontWeight: 500,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                if (isLeadLocked == true) {
                                  setLeadLockedPopUp(true);
                                } else {
                                  setNoteViewpopUp("marketingNote");
                                }
                              }}
                            >
                              View Note
                            </p>
                          ) : (
                            <p
                              style={{
                                color: "#3434e0",
                                fontWeight: 500,
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                if (isLeadLocked == true) {
                                  setLeadLockedPopUp(true);
                                } else {
                                  setNoteAddpopUp("marketingNote");
                                }
                              }}
                            >
                              + Add
                            </p>
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="w-65p semiBold600">Lead History</div>
                        <div className="w-35p regular400 lightColor">
                          <p
                            style={{
                              color: "#3434e0",
                              fontWeight: 500,
                              cursor: "pointer"
                            }}
                          >
                            View
                          </p>{" "}
                        </div>
                      </li>
                      <li>
                        <div className="w-65p semiBold600">
                          Lead Status History
                        </div>
                        <div className="w-35p regular400 lightColor">
                          <p
                            style={{
                              color: "#3434e0",
                              fontWeight: 500,
                              cursor: "pointer"
                            }}
                          >
                            View
                          </p>{" "}
                        </div>
                      </li>
                      <li>
                        <div className="w-65p semiBold600">
                          Upcoming Followup
                        </div>
                        <div className="w-35p regular400 lightColor">NA</div>
                      </li>
                      <li>
                        <div className="w-65p semiBold600">Ad Details</div>
                        <div className="w-35p regular400 lightColor">
                          <p
                            style={{
                              color: "#3434e0",
                              fontWeight: 500,
                              cursor: "pointer"
                            }}
                          >
                            View
                          </p>{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <Box sx={{ mt: 2 }}>
            {leadDetail?.status !== "new" ? (
              <LeaderCreateStepper isDuplicate={isLeadLocked} Msg={leadLockedPopUpMsg} />
            ) : (
              ""
            )}
          </Box>
          {/* </Box> */}
        </Box>

        {editForm && (
          <EditLeadForm
            editForm={editForm}
            handleStateChange={handleStateChange}
          />
        )}

        {open === true && (
          <Dialogue
            openTrue={open}
            handleClose={handleClose}
            headerName="Choose Application Handler"
            Dailogcomponent="application handler"
          />
        )}

        {reason === true && (
          <Dialogue
            openTrue={reason}
            handleClose={handleCloseReason}
            headerName="Reason"
            Dailogcomponent="Reason"
            appliedApplicationId={id}
            modalData={status}
            menu={mainMenu}
          />
        )}

        {deferalReason === true && (
          <Dialogue
            openTrue={deferalReason}
            handleClose={() => setDeferalReason(false)}
            headerName="Next intake month & year"
            Dailogcomponent="deferalReason"
            appliedApplicationId={id}
            modalData={status}
            menu={mainMenu}
          />
        )}
      </MainContainer>

      {/* Dialog For setPassword */}

      <Dialog
        open={openSetPassword}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenSetPassword(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{ width: "100%" }}
      >
        <DialogTitle>{"Change Password"}</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4} lg={12}>
              <form onSubmit={handleSubmit}>
                {/* <InputField
                  label="New Password"
                  handleChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                  widthSize="auto"
                  requiredSymbol
                  error={error.length > 0}
                  helperText={error}
                /> */}
                <TextField
                  type={view ? "text" : "password"}
                  label="New Password"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setView(!view)}>
                          {view ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                {/* <InputField
                  label="Confirm Password"
                  handleChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  widthSize="auto"
                  requiredSymbol
                  error={error.length > 0}
                  helperText={error}
                /> */}

                <TextField
                  type={confirmview ? "text" : "password"}
                  label="Confirm Password"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  margin="normal"
                  error={error.length > 0}
                  helperText={error}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setConfirmView(!confirmview)}
                        >
                          {confirmview ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <Box mt={2}>
                  <Button
                    type="submit"
                    sx={{
                      width: "auto",
                      height: "auto",
                      color: "white",
                      p: 2,
                      backgroundColor: "rgba(20, 30, 60, 1)",
                      borderRadius: "8px",
                      fontWeight: 600,
                      fontSize: "12px",
                      "&:hover": {
                        backgroundColor: "rgba(20, 30, 60, 0.9)"
                      }
                    }}
                  >
                    Change Password
                  </Button>
                </Box>
              </form>
              {/* </Paper> */}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Dialog For Otp enter */}

      <Dialog
        open={openOtpPopUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenOtpPopUp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ width: "100%" }}
        PaperProps={{
          sx: {
            bottom: "35vh",
            width: "850px",
            maxWidth: "585px",
            height: "fit-content",
            maxHeight: "502px",
            marginTop: "35%"
          }
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center" // Optional: To ensure text within DialogTitle is centered
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center" // Center items vertically within the Box
            }}
          >
            <img src="/otp.jpg" width={40} height={40} alt="OTP Icon" />
            <Typography sx={{ fontWeight: 600 }}>Verification Code</Typography>
          </Box>
        </DialogTitle>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          {`Please enter the verification code sent to your ${
            verificationType == "phoneNumber" ? "mobile" : "email"
          }`}
        </Typography>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column" // Center content vertically within DialogContent
          }}
        >
          <Box
            component=""
            // onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                "& input": {
                  outline: "none",
                  borderRadius: "5px",
                  border: "1px solid #DADBE4",
                  padding: "5px",
                  boxSizing: "border-box",
                  textAlign: "center",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#fff"
                }
              }}
            >
              {[0, 1, 2, 3].map((index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={1}
                  placeholder="0"
                  value={
                    otp?.toString()?.split("")[index]
                      ? otp?.toString()?.split("")[index]
                      : ""
                  }
                  onClick={handleOtpChange}
                  readOnly
                />
              ))}

              <input
                type="number"
                ref={inputRef}
                value={otp}
                onChange={handleOtpInputChange}
                style={{
                  opacity: "0",
                  position: "absolute",
                  height: "0",
                  width: "0"
                }}
                disabled={otpLoading}
              />
            </Box>

            {otpLoading ? (
              <CircularProgress
                sx={{
                  width: "25px !important",
                  height: "25px !important",
                  color: "#c51244"
                }}
              />
            ) : (
              <SubmitButton
                title={resendButton ? "Resend" : "Verify"}
                submit=""
                widthSize="150px"
                heightSize="35px"
                type="click"
                handleSubmit={() => {
                  if (isLeadLocked == true) {
                    setLeadLockedPopUp(true);
                  } else {
                    handleVerify();
                  }
                }}
              />
            )}

            {/* <OtpTimer
              minutes={1}
              text="Time:"
              // ButtonText={() => setResendButton(true)}
            /> */}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Dialog Component for CounsilorNote and TeleCallerNote */}
      {noteAddPopUp !== "" && (
        <Dialogue
          openTrue={noteAddPopUp}
          handleClose={() => setNoteAddpopUp("")}
          headerName={
            noteAddPopUp == "counsilorNote"
              ? "Counsilor note"
              : noteAddPopUp == "marketingNote"
              ? "Marketing Note"
              : "Telecaller note"
          }
          Dailogcomponent="counsilorNote"
          appliedApplicationId={id}
        />
      )}

      {/* Dialog Component for CounsilorNote and TeleCallerNote view */}

      {noteViewPopUp !== "" && (
        <Dialogue
          openTrue={noteViewPopUp}
          handleClose={() => setNoteViewpopUp("")}
          headerName={
            noteViewPopUp == "counsilorNote"
              ? "Counsilor note"
              : noteViewPopUp == "marketingNote"
              ? "Marketing Note"
              : "Telecaller note"
          }
          Dailogcomponent="counsilorNoteView"
          appliedApplicationId={id}
          modalData={
            noteViewPopUp == "counsilorNote"
              ? counsilorNote
              : noteViewPopUp == "marketingNote"
              ? marketingNote
              : teleCallerNote
          }
          width="687Px"
          height="318px"
          handleFunction={
            noteViewPopUp == "counsilorNote"
              ? () => setNoteAddpopUp("counsilorNote")
              : noteViewPopUp == "marketingNote"
              ? () => setNoteAddpopUp("marketingNote")
              : () => setNoteAddpopUp("teleCallerNote")
          }
        />
      )}

      {/* Dialog permission */}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={() => setOpenAlert(false)}
        />
      )}

      {/* Lead loked popup */}

      {leadLockedPopUp && (
        <Dialog
          open={leadLockedPopUp}
          onClose={() => setLeadLockedPopUp(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            zIndex: "9999",
            // background:"red",
            "& .MuiDialog-paper": {
              width: "100%",
              borderRadius: "5px",
              p: "15px",
              textAlign: "center"
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end"
            }}
          >
            <Close
              sx={{
                cursor: "pointer"
              }}
              onClick={() => setLeadLockedPopUp(false)}
            />
          </Box>
          <Typography
            sx={{
              color: "#05050F",
              fontSize: "26px",
              fontWeight: "700"
            }}
          >
            Lead locked
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "-10px"
            }}
          >
            <AccessDeniedIcon />
          </Box>
          <Typography
            sx={{
              color: "#717171",
              fontSize: "16px",
              mb: "10px"
            }}
          >
            {leadLockedPopUpMsg}
          </Typography>
        </Dialog>
      )}
    </>
  );
}

export default CreateLeader;
