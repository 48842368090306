import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { findEmployeeNotificationAction } from "../../actions/employeeManagmentAction";
import { useNavigate } from "react-router-dom";

function NotificationList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notificationData, setNotificationData] = useState([]);

  const {
    findEmployeeNotificationLoading,
    findEmployeeNotificationSuccess,
    findEmployeeNotificationError,
  } = useSelector((state) => {
    return state.findEmployeeNotification;
  });

  useEffect(() => {
    dispatch(findEmployeeNotificationAction("notificationOpen"));
  }, [dispatch]);

  useEffect(() => {
    if (findEmployeeNotificationSuccess) {
      setNotificationData(findEmployeeNotificationSuccess?.data?.reverse());
    }
  }, [findEmployeeNotificationSuccess]);

  //   console.log(notificationData,"notificationDatanotificationDatanotificationData");

  const getDaysCount = (date) => {
    const currentDate = new Date();
    const inputDate = new Date(date);
    if (
      inputDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
      inputDate.getUTCMonth() === currentDate.getUTCMonth() &&
      inputDate.getUTCDate() === currentDate.getUTCDate()
    ) {
      return "today";
    }

    const timeDifference = currentDate - inputDate;
    const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };
  return (
    <>
      {notificationData?.map((data) => (
        <Box
          sx={{ display: "flex", mt: 3.5, ml: 1, cursor: "pointer" }}
          onClick={() =>
            navigate(`/Leads/details/${data.leadId}/All Leads/Leads`)
          }
        >
          <Box mt={1}>
            <Avatar>
              <NotificationsIcon sx={{ color: "black" }} />
            </Avatar>
          </Box>
          <Box sx={{ ml: "13px" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              {/* GSL-IND-66518819 TESTKKO staus updated */}
              {findEmployeeNotificationSuccess?.user?.fk_role_id !== "admin"
                ? data.userStatus
                : data.status}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra */}

              {findEmployeeNotificationSuccess?.user?.fk_role_id !== "admin"
                ? data?.userTitle
                : data.title}
            </Typography>

            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "13px",
                fontFamily: "'Open Sans', sans-serif",
              }}
            >
              {/* 4 days ago */}

              {data.createdAt && getDaysCount(data.createdAt) > 0
                ? `${getDaysCount(data.createdAt)} days ago`
                : getDaysCount(data.createdAt)}
            </Typography>
          </Box>
        </Box>
      ))}

      {/* <Box sx={{ display: "flex", mt: 3.5, ml: 1, cursor: "pointer" }}>
        <Box mt={1}>
          <Avatar>
            <NotificationsIcon sx={{ color: "black" }} />
          </Avatar>
        </Box>
        <Box sx={{ ml: "13px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "'Open Sans', sans-serif",
            }}
          >
            GSL-IND-66518819 TESTKKO staus updated
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "'Open Sans', sans-serif",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra
          </Typography>

          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "13px",
              fontFamily: "'Open Sans', sans-serif",
            }}
          >
            4 days ago
          </Typography>
        </Box>
      </Box> */}
    </>
  );
}

export default NotificationList;
