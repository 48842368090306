import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import usePrivilegeCheck from "../../../constants/customHooks/UsePrivilegeCheck.js";
import PremissionDialog from "../../customComponent/premissionDialog.js";
import LeadPassportEditApprove from "./LeadsPassportEditApprove.js";
import LeadPassportVerify from "./leadPassportVerify.js";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    marginTop: "8px",
  },
  hoverElement: {
    "&:hover": {
      color: "#FE0B7A", // Set the hover color
    },
    "&.clicked": {
      backgroundColor: "#FE0B7A",
      color: "white",
    },
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  searchInput: {
    marginLeft: theme.spacing(1),
  },
}));

function LeadPassportApprovals(props) {
  const navigate = useNavigate();
  const { privilege, hasPrivilege, isCreatePrevilage } = usePrivilegeCheck();

  const classes = useStyles();
  const [clicked, setClicked] = useState("passportverify");
  const [addFormCountry, setAddFormCountry] = useState(false);
  const [addFormState, setAddFormState] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  // HandleClose dialog

  const handleCloseDialog = () => {
    setOpenAlert(false);
  };

  const handleClick = (master) => {
    setClicked(master);
  };

  const handleModalClick = () => {
    if (clicked === "Categories") {
      setAddFormCountry(true);
    }

    if (clicked === "Topics") {
      setAddFormState(true);
    }
  };

  //   const searchHandleChange = (value) => {
  //     setSearchValue(value);
  //   };

  if (!hasPrivilege("Approval Request") && privilege !== null) {
    return (
      <Alert severity="error">
        <AlertTitle>warning</AlertTitle>
        You don't have permission to access — <strong>this page.!</strong>
      </Alert>
    );
  }
  return (
    <>
        <Grid item xs={12} lg={6} mt={2}>
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "passportverify" ? "clicked" : ""
            }`}
            label="Lead Passport verify Approval"
            component="a"
            clickable
            sx={{ m: 0.3 }}
            onClick={() => handleClick("passportverify")}
          />
          <Chip
            className={`${classes.hoverElement} ${
              clicked === "passportedit" ? "clicked" : ""
            }`}
            label=" Lead Passport Edit Approval"
            component="a"
            clickable
            sx={{ m: 0.3 }}
            onClick={() => handleClick("passportedit")}
          />
         
        </Grid>

      {clicked === "passportverify" && <LeadPassportVerify submenu="leadPassportApproval" />}
      {clicked === "passportedit" && <LeadPassportEditApprove submenu="leadPassportEditApproval" />}

      {openAlert && (
        <PremissionDialog
          openAlert={openAlert}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}

export default LeadPassportApprovals;
