import React, { useEffect, useState } from "react";

import AssignmentIcon from "@mui/icons-material/Assignment";

import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { findcountryMasterAddAction } from "../../../actions/countryMasterAction";
import {
  changeSopApplicationStatusAction,
  leadDocumentDeleteAction,
  leadSopFindingAction,
  leadSopUploadingAction,
  leadTraveAndImmigrationFindingAction,
  leadTravelAndImmigrationUploadingAction
} from "../../../actions/leadActions/leadDocsActions";
import { useParams } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import CountryFlag from "../../customComponent/CountryFlag";
import { pink } from "@mui/material/colors";
import InputField from "../../customComponent/InputField";
import Swal from "sweetalert2";
import { successTrueMsgAction } from "../../../actions/globalAction";
import { IoEyeOutline } from "react-icons/io5";
import { CiRepeat } from "react-icons/ci";
import { GoDownload } from "react-icons/go";
import { AiTwotoneDelete } from "react-icons/ai";
import { LEAD_DOCUMENT_DELETE_SUCCESS } from "../../../constants/leadConstant";

function TravelImmigration({ handleNext, handleCompleteTick }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [sopFile, setSopFile] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [statusVerify, setStatusVerify] = useState(false);
  const [docStatusVerify, setDocStatusVerify] = useState(false);
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const handleMouseEnter = (icon) => setHoveredIcon(icon);
  const handleMouseLeave = () => setHoveredIcon(null);

  const iconStyle = {
    width: "22px",
    height: "22px",
    marginRight: "10px",
    transition: "color 0.3s ease" // Smooth color transition
  };

  const iconHoverStyle = {
    color: "red" // Color on hover
  };

  let { countryFindLoading, countryFindSuccess } = useSelector((state) => {
    return state.findcountryMasterAdd;
  });

  let {
    leadTravelAndImmigrationUploadingLoading,
    leadTravelAndImmigrationUploadingSuccess,
    leadTravelAndImmigrationUploadingerror
  } = useSelector((state) => {
    return state.leadTravelAndImmigrationUploading;
  });

  let {
    leadTravelAndImmigrationFindingLoading,
    leadTravelAndImmigrationFindingSuccess,
    leadTravelAndImmigrationFindingerror
  } = useSelector((state) => {
    return state.leadTraveAndImmigrationFinding;
  });

  let { leadSopStatusChangeSuccess } = useSelector((state) => {
    return state.changeSopApplicationStatus;
  });

  let { leadDocumentDeleteSuccess } = useSelector((state) => {
    return state.leadDocumentDelete;
  });

  let { successAlertMsgSuccess, successAlertMsgErr } = useSelector((state) => {
    return state.successTrueMsg;
  });

  useEffect(() => {
    if (countryFindSuccess && leadTravelAndImmigrationFindingSuccess) {
      const notEqualCountries = countryFindSuccess.filter((country) => {
        return !leadTravelAndImmigrationFindingSuccess.some(
          (sop) => sop.countryName === country.countryName
        );
      });

      setCountryList(notEqualCountries);
    }
  }, [countryFindSuccess, leadTravelAndImmigrationFindingSuccess]);

  useEffect(() => {
    dispatch(findcountryMasterAddAction(id,"travel"));
  }, [dispatch, leadTravelAndImmigrationUploadingSuccess]);

  useEffect(() => {
    dispatch(leadTraveAndImmigrationFindingAction(id));
  }, [
    dispatch,
    leadTravelAndImmigrationUploadingSuccess,
    id,
    leadSopStatusChangeSuccess,
    leadDocumentDeleteSuccess
  ]);

  // useEffect(() => {
  //   if (leadTravelAndImmigrationFindingSuccess) {
  //     if (leadTravelAndImmigrationFindingSuccess?.length !== 0) {
  //       handleCompleteTick();
  //     }
  //   }
  // }, [leadTravelAndImmigrationFindingSuccess, handleCompleteTick]);

  useEffect(() => {
    if (leadTravelAndImmigrationUploadingSuccess && successAlertMsgSuccess) {
      Swal.fire({
        icon: "success",
        title: "Good job \u{1F44D}",
        text: leadTravelAndImmigrationUploadingSuccess
      });
      dispatch(successTrueMsgAction(false));
    }
  }, [leadTravelAndImmigrationUploadingSuccess, successAlertMsgSuccess]);

  // success alert for file upload

  // useEffect(() => {
  //   if (
  //     (leadDocumentDeleteSuccess &&
  //       leadDocumentDeleteSuccess?.doctype == "travel&immigration") ||
  //     (leadDocumentDeleteSuccess &&
  //       leadDocumentDeleteSuccess?.doctype == "travel")
  //   ) {
  //     Swal.fire({
  //       icon: "success",
  //       title: "Good job \u{1F44D} ",
  //       text: "Deleted successfully"
  //     });
  //     dispatch({ type: LEAD_DOCUMENT_DELETE_SUCCESS, payload: false });
  //   }
  // }, [leadDocumentDeleteSuccess]);

  const handleSopDocChange = (event, countryId, countryName) => {
    console.log(event.target.files[0]);
    const file = event.target.files[0];
    const reader = new FileReader();
    // Read the image file as a data URL
    reader.readAsDataURL(file);

    // Set the previewImage state when the reader has finished loading the image
    reader.onloadend = () => {
      //   setSopFile(reader.result);
      dispatch(
        leadTravelAndImmigrationUploadingAction(
          reader.result,
          countryId,
          id,
          countryName
        )
      );
      dispatch(successTrueMsgAction(true));
    };
  };
  console.log(
    leadTravelAndImmigrationFindingSuccess,
    "leadTravelAndImmigrationFindingSuccessleadTravelAndImmigrationFindingSuccess"
  );
  return (
    <>
      <Grid container style={{ padding: "10px" }} spacing={1}>
        {countryList?.length == 0 && leadTravelAndImmigrationFindingSuccess?.length == 0 ? (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "24px",
              fontFamily: "Montserrat,sans-serif",
              textAlign: "center",
              mt: 10,
              ml: 5,
              mt: 5
            }}
          >
            No documents to show.
          </Typography>
        ):""}
        {countryList?.map((data) => (
          <Grid item xs={6} md={4} lg={3} key={data.id}>
            <Box mt={1} mb={5}>
              <Box
                sx={{
                  width: "auto",
                  height: "82px",
                  backgroundColor: "white",
                  border: "1.5px dashed rgba(25, 118, 210, 0.5)",
                  borderRadius: "5px"
                }}
              >
                {!sopFile && (
                  <label
                  htmlFor={`avatar-file-${data.id}`}
                    style={{
                      margin: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="rounded-flag">
                        <CountryFlag
                          countryCode={data.countryCode}
                          component="stepper"
                          width="39px"
                          heigth="39px"
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: "10px"
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "18px",
                            lineHeight: "24px",
                            fontFamily: "Montserrat,sans-serif"
                          }}
                        >
                          {data?.countryName.split("(")[0]}
                        </Typography>
                        {/* <Typography
                          sx={{
                            color: "#ACB1C6",
                            fontWeight: 300,
                            fontSize: "15px",
                            lineHeight: "20px",
                          }}
                        >
                          Travel&Immigration Documents
                        </Typography> */}
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="/menuIcons/uploadBlue.png"
                        alt="gsl_download"
                        style={{ cursor: "pointer", color: "#1976D2" }}
                      />
                      <div
                        style={{
                          marginLeft: "8px",
                          color: "#1976D2",
                          fontWeight: 400,
                          fontFamily: "Montserrat,sans-serif",
                          fontSize: "20px"
                        }}
                      >
                        Upload
                      </div>
                    </div>
                  </label>
                )}
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.xlsx"
                  id={`avatar-file-${data.id}`}
                  style={{ display: "none" }}
                  onChange={(e) =>
                    handleSopDocChange(e, data.id, data.countryName)
                  }
                />
              </Box>
            </Box>
          </Grid>
        ))}

        {leadTravelAndImmigrationFindingSuccess?.map((travelData, index) => (
          <Grid item xs={6} md={4} lg={3}>
            <Box mt={1} mb={3} position="relative">
              <Box
                // id="container"
                sx={{
                  width: "auto",
                  height: "82px",
                  backgroundColor: "white",
                  border:
                    travelData.status === "verified" ||
                    travelData.status === "approved"
                      ? "1.5px dashed #ECECEC"
                      : "1.5px dashed rgba(255, 153, 0, 1)"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    margin: "15px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="rounded-flag">
                      <CountryFlag
                        countryCode={travelData.countryCode.countryCode}
                        component="stepper"
                        width="39px"
                        heigth="39px"
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: "10px"
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          lineHeight: "24px",
                          fontFamily: "Montserrat,sans-serif"
                        }}
                      >
                        {travelData?.countryName.split("(")[0]}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        {statusVerify[index] !== true && (
                          <>
                            <a
                              href={travelData.pdfUrl}
                              download={travelData.pdfUrl}
                              target="blank"
                              style={{
                                textDecoration: "none",
                                color: "black"
                              }}
                            >
                              <Tooltip title="view">
                                <IconButton
                                  sx={{ padding: 0, marginRight: "10px" }}
                                >
                                  <IoEyeOutline
                                    style={{
                                      ...iconStyle,
                                      color:
                                        hoveredIcon === "eye"
                                          ? iconHoverStyle.color
                                          : "black",
                                      cursor: "pointer"
                                    }}
                                    onMouseEnter={() => handleMouseEnter("eye")}
                                    onMouseLeave={handleMouseLeave}
                                  />
                                </IconButton>
                              </Tooltip>
                            </a>

                            {travelData.status !== "approved" && (

                            <Tooltip title="Change status">
                              <IconButton
                                sx={{ padding: 0, marginRight: "10px" }}
                              >
                                <CiRepeat
                                  style={{
                                    ...iconStyle,
                                    color:
                                      hoveredIcon === "repeat"
                                        ? iconHoverStyle.color
                                        : "black",
                                    cursor: "pointer"
                                  }}
                                  onMouseEnter={() =>
                                    handleMouseEnter("repeat")
                                  }
                                  onMouseLeave={handleMouseLeave}
                                  onClick={() =>
                                    setStatusVerify((prevStatus) => ({
                                      ...prevStatus,
                                      [index]: true
                                    }))
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                            )}


                            <a
                              href={travelData.pdfUrl}
                              download={travelData.pdfUrl}
                              target="blank"
                              style={{
                                textDecoration: "none",
                                color: "black"
                              }}
                            >
                              <Tooltip title="Download">
                                <IconButton
                                  sx={{ padding: 0, marginRight: "10px" }}
                                >
                                  <GoDownload
                                    style={{
                                      ...iconStyle,
                                      color:
                                        hoveredIcon === "download"
                                          ? iconHoverStyle.color
                                          : "black",
                                      cursor: "pointer"
                                    }}
                                    onMouseEnter={() =>
                                      handleMouseEnter("download")
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  />
                                </IconButton>
                              </Tooltip>
                            </a>

                            {travelData.status !== "verified" &&
                            travelData.status !== "approved" ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  sx={{ padding: 0, marginRight: "10px" }}
                                >
                                  <AiTwotoneDelete
                                    style={{
                                      ...iconStyle,
                                      color:
                                        hoveredIcon === "delete"
                                          ? iconHoverStyle.color
                                          : "black",
                                      cursor: "pointer"
                                    }}
                                    onMouseEnter={() =>
                                      handleMouseEnter("delete")
                                    }
                                    onMouseLeave={handleMouseLeave}
                                    onClick={() => {
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Yes, delete it!"
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          dispatch(
                                            leadDocumentDeleteAction(
                                              travelData.id,
                                              id,
                                              "travel&immigration"
                                            )
                                          );
                                          Swal.fire(
                                            "Deleted!",
                                            "Your file has been deleted.\u{1F44D}",
                                            "success"
                                          );
                                        }
                                      });
                                    }}
                                   
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                        {statusVerify[index] === true && (
                          <Box
                            id="icon"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backdropFilter: "blur(8px)",
                              opacity: 0,
                              transition: "opacity 0.3s",
                              zIndex: 1,
                              " &:hover ": {
                                opacity: 1
                              }
                            }}
                          >
                            <>
                              <Chip
                                label="Reupload"
                                color="warning"
                                variant="outlined"
                                style={{
                                  marginLeft: "-10px",
                                  fontSize: "1em",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  color: "rgba(255, 153, 0, 1)",
                                  borderColor: "rgba(255, 153, 0, 1)",
                                  position: "relative",
                                  zIndex: 2
                                }}
                                onClick={() => {
                                  // alert("sdfsdfjklk");
                                  dispatch(
                                    changeSopApplicationStatusAction(
                                      travelData.leadId,
                                      travelData.countryId,
                                      "reupload",
                                      "travelImmigration"
                                    )
                                  );
                                  setStatusVerify((prevStatus) => ({
                                    ...prevStatus,
                                    [index]: false
                                  }));
                                }}
                              />
                              <Chip
                                label="Verify"
                                color="success"
                                variant="outlined"
                                style={{
                                  color: "rgba(50, 157, 0, 1)",
                                  borderColor: "rgba(50, 157, 0, 1)",
                                  marginLeft: "10px",
                                  fontSize: "1em",
                                  position: "relative",
                                  zIndex: 2,
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  dispatch(
                                    changeSopApplicationStatusAction(
                                      travelData.leadId,
                                      travelData.countryId,
                                      "verified",
                                      "travelImmigration"
                                    )
                                  );
                                  setStatusVerify((prevStatus) => ({
                                    ...prevStatus,
                                    [index]: false
                                  }));
                                }}
                              />

                              <Chip
                                label="close"
                                color="success"
                                variant="outlined"
                                style={{
                                  color: "rgba(227, 71, 43)",
                                  borderColor: "rgba(227, 71, 43)",
                                  marginLeft: "10px",
                                  fontSize: "1em",
                                  position: "relative",
                                  zIndex: 2,
                                  cursor: "pointer"
                                }}
                                onClick={() => {
                                  setStatusVerify((prevStatus) => ({
                                    ...prevStatus,
                                    [index]: false
                                  }));
                                }}
                              />
                            </>
                          </Box>
                        )}
                      </Box>
                    </div>
                  </div>

                  <div
                    className="rounded-flag"
                    style={{
                      marginLeft: "auto",
                      paddingLeft: "auto",
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end"
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "8px",
                        color: "#329D00",
                        fontWeight: 400,
                        fontFamily: "Montserrat,sans-serif",
                        fontSize: "20px"
                      }}
                    >
                      Uploaded
                    </div>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        color:
                          travelData.status === "verified" ||
                          travelData.status === "approved"
                            ? "rgba(50, 157, 0, 1)"
                            : "rgba(255, 153, 0, 1)"
                      }}
                    >
                      {travelData.status}
                    </Typography>
                  </div>
                </div>
              </Box>
              {/* <Box
                id="icon"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backdropFilter: "blur(8px)",
                  opacity: 0,
                  transition: "opacity 0.3s",
                  zIndex: 1,
                  " &:hover ": {
                    opacity: 1,
                  },
                }}
              >
                {statusVerify !== true && (
                  <>
                    <label htmlFor="travelDoc-Reupload">
                      <img
                        src="/menuIcons/uploadDoc.png"
                        alt="gsl"
                        style={{
                          fontSize: "2.5em",
                          marginRight: "15px",
                          cursor: "pointer",
                          color: "#f81b82",
                        }}
                      />
                      <input
                        type="file"
                        accept=".pdf,.doc,.docx,.xlsx"
                        id="travelDoc-Reupload"
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleSopDocChange(
                            e,
                            travelData.countryId,
                            travelData.countryName
                          )
                        }
                      />
                    </label>

                    <a href={travelData.pdfUrl} download={travelData.pdfUrl}>
                      <img
                        src="/menuIcons/visibleDoc.png"
                        alt="gsl"
                        style={{
                          fontSize: "2.5em",
                          marginRight: "20px",
                          cursor: "pointer",
                          color: "#f81b82",
                        }}
                      />
                    </a>
                    <img
                      src="/menuIcons/statusChange.png"
                      alt="gsl"
                      style={{
                        fontSize: "2.5em",
                        marginRight: "10px",
                        cursor: "pointer",
                        color: "#f81b82",
                      }}
                      onClick={() => setStatusVerify(true)}
                    />
                    <a
                      href={travelData.pdfUrl}
                      download={travelData.pdfUrl}
                      target="blank"
                    >
                      <img
                        src="/menuIcons/docDownload.png"
                        alt="gsl"
                        style={{
                          fontSize: "2.5em",
                          marginRight: "10px",
                          cursor: "pointer",
                          color: "#f81b82",
                        }}
                      />
                    </a>
                  </>
                )}

                {statusVerify === true && (
                  <>
                    <Chip
                      label="Reupload"
                      color="warning"
                      variant="outlined"
                      style={{
                        color: "warning",
                        marginLeft: "-10px",
                        fontSize: "2.5em",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        dispatch(
                          changeSopApplicationStatusAction(
                            travelData.leadId,
                            travelData.countryId,
                            "reupload",
                            "travelImmigration"
                          )
                        );
                        setStatusVerify(false);
                      }}
                    />
                    <Chip
                      label="Verify"
                      color="success"
                      variant="outlined"
                      style={{
                        color: "warning",
                        marginLeft: "10px",
                        fontSize: "5px",
                      }}
                      onClick={() => {
                        dispatch(
                          changeSopApplicationStatusAction(
                            travelData.leadId,
                            travelData.countryId,
                            "verified",
                            "travelImmigration"
                          )
                        );
                        setStatusVerify(false);
                      }}
                    />

                    <Chip
                      label="close"
                      color="success"
                      variant="outlined"
                      style={{
                        color: "rgba(227, 71, 43)",
                        borderColor: "rgba(227, 71, 43)",
                        marginLeft: "10px",
                        fontSize: "1em",
                        position: "relative", // Maintain relative positioning within the Box
                        zIndex: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setStatusVerify(false);
                      }}
                    />
                  </>
                )}
              </Box> */}
            </Box>
          </Grid>
        ))}

        {/* <Grid item xs={12}>
          <Typography
            sx={{
              fontStyle: "Montserrat",
              fontSize: "19px",
              fontWeight: 400,
            }}
          >
            Travel History
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Typography
              sx={{
                fontStyle: "Montserrat",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              Q:1 <br />
              During the past 5 years have you travelled to a country or
              territory other than the one where you're a citizen or where you
              live now?
            </Typography>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Checkbox
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
            Yes
            <Checkbox
              label="Label"
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
            No
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box mt={1} mb={3} position="relative" sx={{ width: "fit-content" }}>
            <Box
              id="container"
              sx={{
                width: "auto",
                height: "82px",
                backgroundColor: "white",
                border: "1.5px dashed #ECECEC",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "5px",
                }}
              >
                <img
                  src="/menuIcons/docs.png"
                  alt="gsl"
                  sx={{
                    width: "62px",
                    height: 60,
                    cursor: "pointer",
                    color: "#4086f4",
                  }}
                />

                <div>
                  <Typography sx={{ mt: 2 }}>Travel Document</Typography>
                
                </div>
                <div
                  className="rounded-flag"
                  style={{
                    marginLeft: "auto",
                    paddingLeft: "auto",
                    marginTop: "10px",
                  }}
                >
                  <CountryFlag countryCode={"IN"} />
                </div>
              </div>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <div>
            <Typography
              sx={{
                fontStyle: "Montserrat",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              Q:2 <br />
              Have you ever stayed in Canada beyond the validity of your status,
              attended school in Canada without authorization, or worked without
              authorization in Canada?
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
            Yes
            <Checkbox
              label="Label"
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
            No
          </div>
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: "#ACB1C6", margin: 0 }}>Note</p>
          <InputField />
        </Grid>

        <Grid item xs={12}>
          <div>
            <Typography
              sx={{
                fontStyle: "Montserrat",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              Q:3 <br />
              Have you ever stayed in Canada beyond the validity of your status,
              attended school in Canada without authorization, or worked without
              authorization in Canada?
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
            Yes
            <Checkbox
              label="Label"
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
            No
          </div>
        </Grid>*/}
      </Grid>
    </>
  );
}

export default TravelImmigration;
