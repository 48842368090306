import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { pendingLeadStatusDocsAction } from "../../actions/leadActions/leadActions";
import { Box, Button, DialogActions, Slide } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import InputField from "./InputField";

function ReaonDialogue({ open, handleClose, tableId, docName,leadId }) {
//   const Transition = React.forwardRef((props, ref) => (
//     <Slide direction="up" ref={ref} {...props} />
//   ));
  const dispatch = useDispatch();

  const [reason, setReason] = useState("");

  return (
    <Dialog
      open={open}
    //   TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      sx={{ width: "100%" }}
      PaperProps={{
        sx: {
          bottom: "35vh",
          width: "850px",
          maxWidth: "585px",
          height: "fit-content",
          maxHeight: "502px",
          marginTop: "35%",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DialogTitle sx={{ fontSize: "22px", fontWeight: 700 }}>
          {"Rejected Reason"}
        </DialogTitle>

        <RxCross1
          style={{
            width: "30px",
            height: "auto",
            margin: "15px",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </div>
      <DialogContent>
        <Box>
          <InputField
            label="Reason"
            handleChange={(e) => setReason(e.target.value)}
            widthSize="auto"
            value={reason}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(
              pendingLeadStatusDocsAction(tableId, "rejected", docName, reason,leadId)
            );
            handleClose();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ReaonDialogue);
