import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EnquiryStudentApplicationFindByIdAction } from "../../actions/enquiryAction";
import { MainContainer } from "../content/content.element";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  leadEducationDetailsShowAction,
  leadPersonalDetailsShowAction,
} from "../../actions/leadActions/leadActions";
import {
  countryAllAdmissionDocsFindAction,
  leadCountryCommonDocsFindDocsAction,
  leadCountrySpecificDocsFindingDocsAction,
  leadSopFindingAction,
  leadTraveAndImmigrationFindingAction,
  leadWorkDetailsFindAction,
} from "../../actions/leadActions/leadDocsActions";
import CountryFlag from "../customComponent/CountryFlag";

export const Applications = ({ toggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [applications, setApplications] = useState([]);
  const [filterData, SetFilteredData] = useState([]);

  // My Application Response

  let { enquiryStudentApplicationFindSuccess } = useSelector((state) => {
    return state.enquiryStudentApplicationFind;
  });

  // personal details

  let { leadPersonalDetailFindSuccess } = useSelector((state) => {
    return state.leadPersonalDetailsShow;
  });

  // education details

  let { leadEducationDetailFindSuccess } = useSelector((state) => {
    return state.leadEducationDetailsShow;
  });

  // sop find
  let { leadSopFindingSuccess } = useSelector((state) => {
    return state.leadSopFinding;
  });

  // // travel and immigration
  let { leadTravelAndImmigrationFindingSuccess } = useSelector((state) => {
    return state.leadTraveAndImmigrationFinding;
  });

  // work details
  let { leadWorkDetailsFindingSuccess } = useSelector((state) => {
    return state.leadWorkDetailsFind;
  });

  // documents details
  let { commonDocFindSuccess } = useSelector((state) => {
    return state.CommonDocumentApplicationFind;
  });

  let { countryAdmissionFindSuccess } = useSelector((state) => {
    return state.countryAllAdmissionDocsFind;
  });

  let { leadCountrySpecificDocsFindingSuccess } = useSelector((state) => {
    return state.leadCountrySpecificDocsFindingDocs;
  });

  const [data, setData] = useState({});

  useEffect(() => {
    // find MyApplication
    dispatch(EnquiryStudentApplicationFindByIdAction(id));
    // personal details
    dispatch(leadPersonalDetailsShowAction(id));
    // education details
    dispatch(leadEducationDetailsShowAction(id));
    // sop find
    dispatch(leadSopFindingAction(id));
    // travel and immigration
    dispatch(leadTraveAndImmigrationFindingAction(id));
    // work details
    dispatch(leadWorkDetailsFindAction(id));
    // documents
    dispatch(leadCountryCommonDocsFindDocsAction(id));
    dispatch(countryAllAdmissionDocsFindAction(id));
    dispatch(leadCountrySpecificDocsFindingDocsAction(id));
  }, [id]);

  // setstate
  // personal details
  useEffect(() => {
    if (leadPersonalDetailFindSuccess?.data?.personalDetails) {
      setData((prev) => ({
        ...prev,
        ["personalDetails"]: leadPersonalDetailFindSuccess?.data?.personalDetails,
      }));
    }
  }, [leadPersonalDetailFindSuccess]);

  // education details
  useEffect(() => {
    if (leadEducationDetailFindSuccess) {
      setData((prev) => ({
        ...prev,
        ["educationDetails"]: leadEducationDetailFindSuccess,
      }));
    }
  }, [leadEducationDetailFindSuccess]);

  // sop find
  useEffect(() => {
    if (leadSopFindingSuccess) {
      setData((prev) => ({
        ...prev,
        ["sopDetails"]: leadSopFindingSuccess,
      }));
    }
  }, [leadSopFindingSuccess]);

  // travel and immigration
  useEffect(() => {
    if (leadTravelAndImmigrationFindingSuccess) {
      setData((prev) => ({
        ...prev,
        ["travelAndImmigrationDetails"]: leadTravelAndImmigrationFindingSuccess,
      }));
    }
  }, [leadTravelAndImmigrationFindingSuccess]);

  // work details
  useEffect(() => {
    if (leadWorkDetailsFindingSuccess) {
      setData((prev) => ({
        ...prev,
        ["workDetails"]: leadWorkDetailsFindingSuccess,
      }));
    }
  }, [leadWorkDetailsFindingSuccess]);

  // document details
  useEffect(() => {
    if (
      commonDocFindSuccess ||
      countryAdmissionFindSuccess ||
      leadCountrySpecificDocsFindingSuccess
    ) {
      const uniqueDocumentIds = new Set();
      let resultData = leadCountrySpecificDocsFindingSuccess?.filter(
        (specificData) => {
          if (!uniqueDocumentIds.has(specificData.DocId)) {
            uniqueDocumentIds.add(specificData.DocId);
            return true;
          }
          return false;
        }
      );
      let document = {
        documentDetails: commonDocFindSuccess,
        // countryDetails: countryAdmissionFindSuccess,
        countryDoc: resultData,
      };
      setData((prev) => ({
        ...prev,
        ["document"]: document,
      }));
    }
  }, [
    commonDocFindSuccess,
    countryAdmissionFindSuccess,
    leadCountrySpecificDocsFindingSuccess,
  ]);

  useEffect(() => {
    if (enquiryStudentApplicationFindSuccess) {
      setApplications(enquiryStudentApplicationFindSuccess.data);
    }
  }, [enquiryStudentApplicationFindSuccess]);


    console.log(applications,"applicationsapplicationsapplications")
  return (
    <MainContainer active={toggle}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          my: "20px",
        }}
      >
        <KeyboardBackspaceIcon
          onClick={() => navigate(-1)}
          sx={{ cursor: "pointer" }}
        />
        <Typography
          sx={{
            color: "#05050F",
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          Back
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            mb: "40px",
          }}
        >
          <Box
            sx={{
              filter: "drop-shadow(0px 0px 10px #00000012)",
              backgroundColor: "#ffffff",
              padding: "25px",
              maxWidth: "1850px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "Montserrat,sans-serif",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Personal Details
            </Typography>

            <Box
              sx={{
                display: "grid",
                gap: "20px",
                mt: "10px",
                gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Passport Information
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Name as it appears in passport
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.personalDetails?.passportName
                      ? data?.personalDetails?.passportName
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Passport Issue location
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.personalDetails?.passportLocation
                      ? data?.personalDetails?.passportLocation
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Passport number
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.personalDetails?.passportNumber
                      ? data?.personalDetails?.passportNumber
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Passport Expairy Date
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.personalDetails?.passportExpire
                      ? data?.personalDetails?.passportExpire
                      : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Permanent Address
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "fit-content",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Country
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.country
                          ? data?.personalDetails?.country
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        State
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.state
                          ? data?.personalDetails?.state
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Address Line 1
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.addressLine1
                          ? data?.personalDetails?.addressLine1
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Address Line 2
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.addressLine2
                          ? data?.personalDetails?.addressLine2
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        City
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.city
                          ? data?.personalDetails?.city
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        State
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.state
                          ? data?.personalDetails?.state
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Current Address
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "fit-content",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Country
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.currentCountry
                          ? data?.personalDetails?.currentCountry
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        State
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.crrentState
                          ? data?.personalDetails?.crrentState
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Address Line 1
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.currentAddressLine1
                          ? data?.personalDetails?.currentAddressLine1
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Address Line 2
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.currentAddressLine2
                          ? data?.personalDetails?.currentAddressLine2
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        City
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.currentCity
                          ? data?.personalDetails?.currentCity
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        State
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.personalDetails?.crrentState
                          ? data?.personalDetails?.crrentState
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Emergency contact number
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.personalDetails?.emergencyName
                      ? data?.personalDetails?.emergencyName
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Mobile
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.personalDetails?.emergencyMobile
                      ? data?.personalDetails?.emergencyMobile
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.personalDetails?.emergencyEmail
                      ? data?.personalDetails?.emergencyEmail
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Relationship
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.personalDetails?.emergencyRelationship
                      ? data?.personalDetails?.emergencyRelationship
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              filter: "drop-shadow(0px 0px 10px #00000012)",
              backgroundColor: "#ffffff",
              padding: "25px",
              maxWidth: "1850px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "Montserrat,sans-serif",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Education
            </Typography>

            <Box
              sx={{
                display: "grid",
                gap: "20px",
                mt: "10px",
                gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  High School
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Completion year :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.educationDetails?.aLeadEducationDeatails
                      ?.highSchoolCompletionYear
                      ? data?.educationDetails?.aLeadEducationDeatails
                          ?.highSchoolCompletionYear
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Board :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.educationDetails?.aLeadEducationDeatails
                      ?.highSchoolBoard
                      ? data?.educationDetails?.aLeadEducationDeatails
                          ?.highSchoolBoard
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#010101",
                    }}
                  >
                    Score :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#848484",
                    }}
                  >
                    {data?.educationDetails?.aLeadEducationDeatails
                      ?.highSchoolScore
                      ? data?.educationDetails?.aLeadEducationDeatails
                          ?.highSchoolScore
                      : "-"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Higher Secondary
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    width: "fit-content",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Completion year :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.educationDetails?.aLeadEducationDeatails
                        ?.higherSecondaryCompletionYear
                        ? data?.educationDetails?.aLeadEducationDeatails
                            ?.higherSecondaryCompletionYear
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Stream :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.educationDetails?.aLeadEducationDeatails
                        ?.higherSecondarySteam
                        ? data?.educationDetails?.aLeadEducationDeatails
                            ?.higherSecondarySteam
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Board :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.educationDetails?.aLeadEducationDeatails
                        ?.higherSecondaryBoard
                        ? data?.educationDetails?.aLeadEducationDeatails
                            ?.higherSecondaryBoard
                        : "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#010101",
                      }}
                    >
                      Score :
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#848484",
                      }}
                    >
                      {data?.educationDetails?.aLeadEducationDeatails
                        ?.higherSecondaryScore
                        ? data?.educationDetails?.aLeadEducationDeatails
                            ?.higherSecondaryScore
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Academic History
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "fit-content",
                    flexWrap: "wrap",
                  }}
                >
                  {data?.educationDetails?.educationAcademic?.map((item, i) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "fit-content",
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Course
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {item?.course ? item?.course : "-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Institute
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {item?.institute ? item?.institute : "-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Country
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {item?.country ? item?.country : "-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Level of study
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {item?.levelOfStudy ? item?.levelOfStudy : "-"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "fit-content",
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Start date
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {item?.startDate ? item?.startDate : "-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Result Info
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {item?.resultInfo ? item?.resultInfo : "-"}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  ))}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  English Language and Standardized Exam
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "fit-content",
                    flexWrap: "wrap",
                  }}
                >
                  {data?.educationDetails?.educationEnglishTest?.length > 0 ? (
                    data?.educationDetails?.educationEnglishTest?.map(
                      (item, i) => (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "fit-content",
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Test Taken :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.test ? item?.test : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Date of test :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.date ? item?.date : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Score :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.score ? item?.score : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      )
                    )
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "fit-content",
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Test Taken :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Date of test :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Score :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              filter: "drop-shadow(0px 0px 10px #00000012)",
              backgroundColor: "#ffffff",
              padding: "25px",
              maxWidth: "1850px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "Montserrat,sans-serif",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Sop
            </Typography>

            <Box
              sx={{
                display: "grid",
                gap: "20px",
                mt: "10px",
                gridTemplateColumns: "repeat(auto-fit,minmax(250px,3fr))",
              }}
            >
              {data?.sopDetails?.map((item, i) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "62px 1fr 30px",
                      alignItems: "center",
                      gap: "20px",
                      border: "1.5px dashed #ECECEC",
                      padding: "10px",
                      width: "fit-content",
                      // borderRadius:"8px",
                    }}
                  >
                    {/* <Doc /> */}
                    <img
                      src="/menuIcons/docs.png"
                      alt="gsl_download"
                      style={{ cursor: "pointer" }}
                    />
                    <Box>
                      <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                        SOP Document
                      </Typography>
                      <Typography
                        component={"a"}
                        href={item?.pdfUrl}
                        sx={{
                          fontSize: "14px",
                          color: "rgba(50, 157, 0, 1)",
                          textDecoration: "none",
                        }}
                        target="_blank"
                      >
                        View
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        "& img": {
                          m: "0 !important",
                        },
                      }}
                    >
                      <CountryFlag
                        countryCode={item?.countryCode?.countryCode}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box
            sx={{
              filter: "drop-shadow(0px 0px 10px #00000012)",
              backgroundColor: "#ffffff",
              padding: "25px",
              maxWidth: "1850px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "Montserrat,sans-serif",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Travel and Immigration
            </Typography>

            <Box
              sx={{
                display: "grid",
                gap: "20px",
                mt: "10px",
                gridTemplateColumns: "repeat(auto-fit,minmax(280px,3fr))",
              }}
            >
              {data?.travelAndImmigrationDetails?.map((item) => {
                return (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "62px 1fr 30px",
                      alignItems: "center",
                      gap: "20px",
                      border: "1.5px dashed #ECECEC",
                      padding: "10px",
                      width: "fit-content",
                      // borderRadius:"8px",
                    }}
                  >
                    {/* <Doc /> */}
                    <img
                      src="/menuIcons/docs.png"
                      alt="gsl_download"
                      style={{ cursor: "pointer" }}
                    />
                    <Box>
                      <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                        Travel Document
                      </Typography>
                      <Typography
                        component={"a"}
                        href={item?.pdfUrl}
                        sx={{
                          fontSize: "14px",
                          color: "rgba(50, 157, 0, 1)",
                          textDecoration: "none",
                        }}
                        target="_blank"
                      >
                        View
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        "& img": {
                          m: "0 !important",
                        },
                      }}
                    >
                      <CountryFlag countryCode={"IN"} />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box
            sx={{
              filter: "drop-shadow(0px 0px 10px #00000012)",
              backgroundColor: "#ffffff",
              padding: "25px",
              maxWidth: "1850px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "Montserrat,sans-serif",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Work Details
            </Typography>

            <Box
              sx={{
                display: "grid",
                gap: "20px",
                mt: "10px",
                gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Work Details
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "fit-content",
                    flexWrap: "wrap",
                  }}
                >
                  {data?.workDetails?.leadWorkDeatails?.length > 0 ? (
                    data?.workDetails?.leadWorkDeatails?.map((item, i) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "fit-content",
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Job titles :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.jobTitle ? item?.jobTitle : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Name of organization :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.nameOfOrganization
                                ? item?.nameOfOrganization
                                : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Address of organization :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.addressOfOrganization
                                ? item?.addressOfOrganization
                                : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Organization phone number :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.organizationPhoneNumber
                                ? item?.organizationPhoneNumber
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "fit-content",
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Start date :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.startDate ? item?.startDate : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              End date :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.endDate ? item?.endDate : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    ))
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "fit-content",
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Job titles :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Name of organization :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Address of organization :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Organization phone number :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "fit-content",
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Start date :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            End date :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "fit-content",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Referee Details
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "fit-content",
                    flexWrap: "wrap",
                  }}
                >
                  {data?.workDetails?.leadRefereeDeatails?.length > 0 ? (
                    data?.workDetails?.leadRefereeDeatails?.map((item, i) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "fit-content",
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Name
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.refereeName ? item?.refereeName : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              position
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.position ? item?.position : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Institution
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.institution ? item?.institution : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Work email
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.workEmail ? item?.workEmail : "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "fit-content",
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Known how long?
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.personKnow ? item?.personKnow : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Mobile
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.mobile ? item?.mobile : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Relationship
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.relationship ? item?.relationship : "-"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "1fr 2fr",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#010101",
                              }}
                            >
                              Address
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "#848484",
                              }}
                            >
                              {item?.address ? item?.address : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    ))
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "fit-content",
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Name
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            position
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Institution
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Work email
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "fit-content",
                        }}
                      >
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Known how long?
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Mobile
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Relationship
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#010101",
                            }}
                          >
                            Address
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#848484",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              filter: "drop-shadow(0px 0px 10px #00000012)",
              backgroundColor: "#ffffff",
              padding: "25px",
              maxWidth: "1850px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "Montserrat,sans-serif",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Documents
            </Typography>

            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "14px",
                  mt: "10px",
                  lineHeight: "20px",
                }}
              >
                Common Document
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gap: "20px",
                  mt: "10px",
                  gridTemplateColumns: "repeat(auto-fit,minmax(290px,3fr))",
                }}
              >
                {data?.document?.documentDetails?.length > 0 ? (
                  data?.document?.documentDetails?.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "62px 1fr",
                          // gridTemplateColumns: "62px 1fr 30px",
                          alignItems: "center",
                          gap: "20px",
                          border: "1.5px dashed #ECECEC",
                          padding: "10px",
                          width: "fit-content",
                          // borderRadius:"8px",
                        }}
                      >
                        {/* <Doc /> */}
                        <img
                          src="/menuIcons/docs.png"
                          alt="gsl_download"
                          style={{ cursor: "pointer" }}
                        />
                        <Box>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            {item?.Common_Doc?.DocName}
                          </Typography>
                          <Typography
                            component={"a"}
                            href={item?.pdfUrl}
                            sx={{
                              fontSize: "14px",
                              color: "rgba(50, 157, 0, 1)",
                              textDecoration: "none",
                            }}
                            target="_blank"
                          >
                            View
                          </Typography>
                        </Box>
                        {/* <Box
                      sx={{
                        "& img": {
                          m: "0 !important",
                        },
                      }}
                    >
                      <CountryFlag countryCode={"IN"} />
                    </Box> */}
                      </Box>
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "Montserrat,sans-serif",
                      fontSize: "16px",
                      my: "10px",
                      lineHeight: "20px",
                    }}
                  >
                    Documentments needs to be uploaded
                  </Typography>
                )}
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "14px",
                  mt: "10px",
                  lineHeight: "20px",
                }}
              >
                Country Document
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gap: "20px",
                  mt: "10px",
                  gridTemplateColumns: "repeat(auto-fit,minmax(290px,3fr))",
                }}
              >
                {data?.document?.countryDoc?.length > 0 ? (
                  data?.document?.countryDoc?.map((item) => {
                    return (
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "62px 1fr 30px",
                          alignItems: "center",
                          gap: "20px",
                          border: "1.5px dashed #ECECEC",
                          padding: "10px",
                          width: "fit-content",
                          // borderRadius:"8px",
                        }}
                      >
                        {/* <Doc /> */}
                        <img
                          src="/menuIcons/docs.png"
                          alt="gsl_download"
                          style={{ cursor: "pointer" }}
                        />
                        <Box>
                          <Typography
                            sx={{ fontSize: "16px", fontWeight: "600" }}
                          >
                            {item?.docName}
                          </Typography>
                          <Typography
                            component={"a"}
                            href={item?.pdfUrl}
                            sx={{
                              fontSize: "14px",
                              color: "rgba(50, 157, 0, 1)",
                              textDecoration: "none",
                            }}
                            target="_blank"
                          >
                            View
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            "& img": {
                              m: "0 !important",
                            },
                          }}
                        >
                          <CountryFlag countryCode={item?.countryCode} />
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <>
                    {" "}
                    <Typography
                      sx={{
                        fontFamily: "Montserrat,sans-serif",
                        fontSize: "16px",
                        my: "10px",
                        lineHeight: "20px",
                      }}
                    >
                      Documentments needs to be uploaded
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {/* <Box
            sx={{
              filter: "drop-shadow(0px 0px 10px #00000012)",
              backgroundColor: "#ffffff",
              padding: "25px",
              maxWidth: "1850px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontFamily: "Montserrat,sans-serif",
                fontSize: "20px",
                lineHeight: "24px",
                mb: "10px",
              }}
            >
              Application details
            </Typography>
            <Box
              sx={{
                display: "grid",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Basic Details
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gap: "20px",
                    gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Application ID
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.applicationId
                          ? data?.application?.applicationId
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Application Method
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.applyMethod
                          ? data?.application?.applyMethod
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Date
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.createdAt
                          ? data?.application?.createdAt?.split("T")[0]
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Status
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.methodStatus
                          ? data?.application?.methodStatus
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Lead Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.lead?.firstName
                          ? data?.application?.lead?.firstName
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        University
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.universityName
                          ? data?.application?.universityName
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        University ID
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.universityId
                          ? data?.application?.universityId
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Course
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.courseName
                          ? data?.application?.courseName
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Interviewer
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.lead?.interviewer
                          ? data?.application?.lead?.interviewer
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        VISA Officer
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.lead?.visaHandler
                          ? data?.application?.lead?.visaHandler
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Application Handler
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.lead?.applicationHandler
                          ? data?.application?.lead?.applicationHandler
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        University Handler
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {data?.application?.lead?.universityHandler
                          ? data?.application?.lead?.universityHandler
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Visa Details
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gap: "20px",
                    gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Applied Date :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {"-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Card Detail :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {"-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Visa Receipt No :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {"-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Registration amount :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        {"-"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Montserrat,sans-serif",
                    fontSize: "14px",
                    mt: "0px",
                    lineHeight: "20px",
                  }}
                >
                  Payment Details
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gap: "20px",
                    gridTemplateColumns: "repeat(auto-fit,minmax(400px,3fr))",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Applied Date :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        -
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Card Detail
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        -
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Visa Receipt No :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        -
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Registration amount
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        -
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "fit-content",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Tuition Fund
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        -
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2fr",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#010101",
                        }}
                      >
                        Source funding
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          color: "#848484",
                        }}
                      >
                        -
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box> */}
        </Box>

        <Typography
          sx={{
            fontWeight: 700,
            fontFamily: "Montserrat,sans-serif",
            fontSize: "20px",
            lineHeight: "24px",
            ml:2,
            mb:5
          }}
        >
          Applications
        </Typography>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit,minmax(200px, 1fr))",
          }}
        >
          {applications?.map((value) => (
            <Card
              sx={{
                width: "100%",
                maxWidth: "450px",
                boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.1)",
                height: "auto",
                borderRadius: "12px",
                p: "24px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mb: 10,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#777AAE",
                  }}
                >
                  Application No:
                  {value.applicationNo ? value.applicationNo : ""}
                </Typography>
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 700,
                    color: "#0E0106",
                  }}
                >
                  {value.courseName ? value.courseName : ""}
                </Typography>
              </Box>

              <Box>
                <Typography
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  {value.universityName ? value.universityName : ""}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#777AAE",
                  }}
                >
                  {value.universityCountry
                    ? value.universityCountry.split("(")[0]
                    : ""}
                </Typography>
              </Box>

              <Box style={{ width: "100%" }}>
                <Typography
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#686868",
                  }}
                >
                  Applied On
                </Typography>
                <Typography
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "22.4px",
                  }}
                >
                  {value.appliedOn ? value.appliedOn.split("T")[0] : ""}
                </Typography>
              </Box>

              <Box
                sx={{ display: "flex", flexWrap: "wrap", columnGap: "30px" }}
              >
                <Box
                  style={{
                    display: "block",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 700,
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    Status:
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      fontWeight: 700,
                      fontSize: "12px",
                      // lineHeight: "22.4px",
                      color: "#EFBA00",
                    }}
                  >
                    {value.status ? value.status : ""}
                  </Typography>
                </Box>

                <Box
                  lg={5}
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "20px",
                  }}
                >
                  <Grid>
                    <Typography
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 700,
                        fontSize: "12px",
                        color: "#FF1B6F",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(
                          `/Application/applicationDetails/${value.id}`
                        )
                      }
                    >
                      View
                    </Typography>
                  </Grid>
                  {/* <Grid>
                <Typography
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: 700,
                    fontSize: "12px",
                    color: "#777AAE",
                  }}
                >
                  Withdraw
                </Typography>
              </Grid> */}
                </Box>
              </Box>
            </Card>
            // <Card
            //   sx={{
            //     minWidth: 275,
            //     maxWidth: 380,
            //     boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.1)",
            //     height: "auto",
            //     borderRadius: "12px",
            //     mt: 3,
            //     mr: 3,
            //   }}
            // >
            //   <CardContent>
            //     <Grid container sx={{ mt: 1.2, mb: 1.2 }}>
            //       <Grid lg={12}>
            //         <Typography
            //           sx={{
            //             fontFamily: "'Poppins', sans-serif",
            //             fontWeight: 400,
            //             fontSize: "14px",
            //             color: "#777AAE",
            //           }}
            //         >
            //           Application No:
            //           {value.applicationNo ? value.applicationN : ""}
            //         </Typography>
            //       </Grid>
            //       <Grid xs={11} lg={11} sx={{ mt: 1 }}>
            //         <Typography
            //           sx={{
            //             fontSize: "14px",
            //             fontFamily: "'Poppins', sans-serif",
            //             fontWeight: 700,
            //             color: "#0E0106",
            //           }}
            //           gutterBottom
            //         >
            //           {value.courseName ? value.courseName : ""}
            //         </Typography>
            //       </Grid>
            //     </Grid>
            //     <Grid container>
            //       <Grid style={{ display: "block" }} xs={12} lg={12}>
            //         <Typography
            //           style={{
            //             fontFamily: "'Poppins', sans-serif",
            //             fontWeight: 400,
            //             fontSize: "14px",
            //             color: "black",
            //             marginBottom: "8px",
            //           }}
            //         >
            //           {value.universityName ? value.universityName : ""}
            //         </Typography>
            //         <Typography
            //           style={{
            //             fontFamily: "'Poppins', sans-serif",
            //             fontWeight: 400,
            //             fontSize: "14px",
            //             color: "#777AAE",
            //           }}
            //         >
            //           {value.universityCountry
            //             ? value.universityCountry.split("(")[0]
            //             : ""}
            //         </Typography>
            //       </Grid>

            //       <Grid lg={12} style={{ display: "block", marginTop: "14px" }}>
            //         <Typography
            //           style={{
            //             fontFamily: "'Poppins', sans-serif",
            //             fontWeight: 400,
            //             fontSize: "14px",
            //             color: "#686868",
            //             marginBottom: "8px",
            //           }}
            //         >
            //           Applied On
            //         </Typography>
            //         <Typography
            //           style={{
            //             fontFamily: "'Poppins', sans-serif",
            //             fontWeight: 400,
            //             fontSize: "14px",
            //             lineHeight: "22.4px",
            //           }}
            //         >
            //           {value.appliedOn ? value.appliedOn.split("T")[0] : ""}
            //         </Typography>
            //       </Grid>
            //       <Grid sx={{ display: "flex", mt: -1 }}>
            //         <Grid
            //           lg={5.5}
            //           style={{
            //             display: "block",
            //             marginTop: "25px",
            //             maxWidth: "35%",
            //           }}
            //         >
            //           <Typography
            //             style={{
            //               fontFamily: "'Poppins', sans-serif",
            //               fontWeight: 700,
            //               fontSize: "12px",
            //               color: "black",
            //               marginBottom: "8px",
            //             }}
            //           >
            //             Status:
            //           </Typography>
            //           <Typography
            //             style={{
            //               fontFamily: "'Poppins', sans-serif",
            //               fontWeight: 700,
            //               fontSize: "12px",
            //               lineHeight: "22.4px",
            //               color: "#EFBA00",
            //             }}
            //           >
            //             {value.status ? value.status : ""}
            //           </Typography>
            //         </Grid>
            //         <Grid
            //           lg={5}
            //           style={{
            //             display: "flex",
            //             marginTop: "55px",
            //             marginLeft: "15px",
            //           }}
            //         >
            //           {/* <Grid>
            //             <Typography
            //               style={{
            //                 fontFamily: "'Poppins', sans-serif",
            //                 fontWeight: 700,
            //                 fontSize: "12px",
            //                 color: "black",
            //               }}
            //             >
            //               Edit
            //             </Typography>
            //           </Grid> */}
            //           <Grid sx={{ ml: 3 }}>
            //             <Typography
            //               style={{
            //                 fontFamily: "'Poppins', sans-serif",
            //                 fontWeight: 700,
            //                 fontSize: "12px",
            //                 color: "#FF1B6F",
            //                 cursor: "pointer",
            //               }}
            //               onClick={() => navigate(`/student/application/view/${value.id}`)}
            //             >
            //               View
            //             </Typography>
            //           </Grid>
            //           <Grid sx={{ ml: 3 }}>
            //             <Typography
            //               style={{
            //                 fontFamily: "'Poppins', sans-serif",
            //                 fontWeight: 700,
            //                 fontSize: "12px",
            //                 color: "#777AAE",
            //               }}
            //             >
            //               Withdraw
            //             </Typography>
            //           </Grid>
            //         </Grid>
            //       </Grid>
            //     </Grid>
            //   </CardContent>
            // </Card>
          ))}
        </Box>

        {applications?.length == 0 ? (
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 700,
              fontSize: "28.55px",
              color: "#777AAE",
              textAlign: "center",
              my: "20px",
            }}
          >
            No Applications to show
          </Typography>
        ) : (
          ""
        )}
      </Box>
    </MainContainer>
  );
};
